<!-- 气象条件 -->
<template>
  <div class="_weather">
    <div class="weather_head">
      <div class="title">气象信息</div>
      <div class="more" @click="wether_pup(2)">
        <span>更多</span>
        <img src="~@/assets/zong_lan_ye_img/jiantou_more.png" />
      </div>
    </div>
    <div class="nei">
      <div class="weather_img">
        <img :src="weatherIcon" style="width: 100%" alt="" />
      </div>
      <!-- 气温 -->
      <div class="weather_text">
        <span>{{ weather && weather.conditionDay ? weather.conditionDay : "晴天" }}</span>
        <div class="fontNow">{{ weather.temp || 30 }}˚C</div>
        <span>今日&nbsp;&nbsp;{{ weather.minTemp || 20 }}˚C~{{ weather.maxTemp || 30 }}˚C</span>
        <span class="fontGray">明日&nbsp;&nbsp;{{ weatherList[1] && weatherList[1].minTemp ? weatherList[1].minTemp : 20 }}˚C~{{
            weatherList[1] && weatherList[1].maxTemp
              ? weatherList[1].maxTemp
          : 30
          }}˚C</span>
      </div>
      <div class="fengxiang">
        <div class="fx-item">
          <img src="~@/assets/zong_lan_ye_img/qiuliu.png" alt="" />
          <div class="fx-item-text">
            <span>{{ weather.windSpeed || 2.0 }}m/s</span>
            <span class="fontGray">风速</span>
          </div>
        </div>
        <div class="fx-item">
          <img src="~@/assets/zong_lan_ye_img/fengxiang.png" alt="" />
          <div class="fx-item-text">
            <span>{{ weather.windDirDay || "西南" }}</span>
            <span class="fontGray">风向</span>
          </div>
        </div>
      </div>
      <div class="qifei">
        <img v-if="weather.flyStatus == 0" src="~@/assets/zong_lan_ye_img/buYiQiFei.png" style="width: 100%" alt="" />
        <img v-else src="~@/assets/zong_lan_ye_img/qifei.png" style="width: 100%" alt="" />
      </div>
    </div>
    <!-- 四个角标 -->
    <div class="corn corn1"></div>
    <div class="corn corn2"></div>
    <div class="corn corn3"></div>
    <div class="corn corn4"></div>
  </div>
</template>

<script>
// 接口文件
import API from "@/api";
import { get } from "lockr";
import { mapGetters } from "vuex";
import axios from "axios";
const juheApiUrl = process.env.VUE_APP_JUHE_API_TQ_URL;
const juheApiAK = process.env.VUE_APP_JUHE_API_QT_KEY;
export default {
  data() {
    return {
      filter1: {
        // 气象条件
        organizationId: null,
      },
      options: [], //级联选择器
      weather: {},
      weatherList: [], //天气状态
      weatherIcon: require("@/assets/images/weather/icon/day/00.png"), //天气状态图片
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  async mounted() {
    await this.threeWeather();
    this.getOptions();
    // await this.getWeatherInfo();
  },
  methods: {
    // 返回地区
    async getOptions() {
      let res = await API.DEPARTMENT.Drd();
      // console.log('天气-地区', res);
      this.options = [res] || [];
    },
    // 返回6天天气
    async threeWeather() {
      let organId = { organId: this.user_info["departmentId"] };
      console.log('组织id', organId);
      console.log('组织id', this.user_info);
      let res = await API.WEATHER.getNewWeather(organId);
      console.log('天气-6天天气', res);
      if (res.length > 0) {
        // let qdtq = {
        //   "rain": "1",
        //   "temp": "31",
        //   "flyStatus": 1,
        //   "maxTemp": "33",
        //   "windLevelNight": "1",
        //   "minTemp": "24",
        //   "conditionDay": "晴",
        //   "nightIcon": "30",
        //   "dayIcon": "0",
        //   "windLevelDay": "3",
        //   "humidity": "91",
        //   "windDirection": "东风",
        //   "conditionNight": "晴",
        //   "windSpeed": "1.4",
        //   "windDirDay": "东风",
        //   "windDirNight": "北风"
        // }
        console.log('this.$store.state.user.user_info.username', this.$store.state.user.user_info.username);
        
        // if (this.$store.state.user.user_info.username == "dongtai_back") {
        //   this.weather = qdtq;
        // } else if (this.$store.state.user.user_info.username == "LFKS123") {
        //   this.weather = LFKS123tq;
        // } else {
        //   this.weather = res[0];
        // }
        this.weather = res[0];
      }
      this.weatherList = res;
      // console.log(this.weatherList,'this.weatherList');
      const date = new Date();
      let time = date.getHours();
      console.log(this.weather);
      this.weatherList.conditionDay = time >= 18 ? this.weather.conditionNight : this.weather.conditionDay;
      this.weatherIcon = require(`@/assets/images/weather/icon/iconfont/W${time >= 18 ? this.weather.nightIcon : this.weather.dayIcon}.png`);
    },
    // 打开弹框
    wether_pup(num) {
      this.$emit("pup", num);
    },
    // 获取天气信息
    async getWeatherInfo() {
      let cityName = '';
      let cityAdCode = '';
      let res = await API.MAP.getCityNameByIP({});
      console.log('res百度天气', res);
      if (res.status == 200) {
        cityName = res.data.city.replace('', '市').replace('', '县').replace('', '省').replace('', '区');
        cityAdCode = res.data.adcode;
      }
      let res1 = await API.MAP.getWeatherByCityAdCode( cityAdCode );
      console.log('res1天气信息', res1);
      // let url3 = `${juheApiUrl}?key=${juheApiAK}&city=${cityName}`;
      if (res1 && res1.data && res1.data.forecasts) {
        if (res1.data.forecasts.length > 0) {
          let data = res1.data.forecasts[0].casts;    
          this.weather = data[0];
          this.weatherList = data;
          const date = new Date();
          let time = date.getHours();
          this.weatherList.conditionDay = time >= 18 ? this.weather.conditionNight : this.weather.conditionDay;
          this.weatherIcon = require(`@/assets/images/weather/icon/iconfont/W${time >= 18 ? this.weather.nightIcon : this.weather.dayIcon}.png`);
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
._weather {
  width: 100%;
  height: 14.5vh;
  background-color: rgba(35, 47, 79, 0.75);
  //border-radius: 12px;
  color: #fff;
  font-family: PingFangSC-Semibold, PingFang SC;
  margin-bottom: 12px;
  background: linear-gradient(360deg, #14203E 0%, rgba(28, 74, 93, 0.91) 100%);
  position: relative;

  // 四个角标
  .corn {
    width: 22px;
    height: 20px;
    //border: 1px solid #00F5FF;
    position: absolute;
  }

  .corn1 {
    top: 0;
    left: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
  }

  .corn2 {
    top: 0;
    right: 0;
    background-image: url("~@/assets/img/corn1.png");
    background-repeat: no-repeat;
    transform: rotate(90deg);
    //back
  }

  .corn3 {
    bottom: 0;
    left: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
  }

  .corn4 {
    bottom: 0;
    right: 0;
    background-image: url("~@/assets/img/corn3.png");
    background-repeat: no-repeat;
    transform: rotate(-90deg);
  }
}

.weather_head {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  //padding: 0 12px 0 12px;
  align-items: center;
  //background-image: url("~@/assets/zong_lan_ye_img/kuang_title_bg.png");
  background-repeat: no-repeat;
  background-size: 274px 42px;
  border-bottom: 1px solid #474772;

  .title {
    font-size: 18px;
    line-height: 28px;
    margin-left: 14px;
    font-weight: 600;
    height: 100%;
    width: 30%;
    background-image: url("~@/assets/img/title.png");
    background-repeat: no-repeat;
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    color: #FFFFFF;
    display: flex;
    align-items: center;
  }

  .more {
    font-size: 14px;
    line-height: 25px;
    font-weight: 400;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    padding-right: 10px;

    img {
      margin-left: 3px;
      width: 11px;
      height: 17px;
    }
  }
}

/deep/ .el-input__inner {
  height: 22px !important;
  background: transparent;
  border: 0px solid transparent;
}

.nei {
  width: 100%;
  box-sizing: border-box;
  // padding: 12px 25px 7px 20px;
  padding: 0 25px 0 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .weather_img {
    width: 50px;
    // height: 50px;
  }

  .weather_text {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ffffff;
    line-height: 17px;

    .fontNow {
      font-weight: bold;
      //color: #ffe61f;
      color: #00F5FF;
      line-height: 30px;
      font-size: 26px;
    }
  }

  .fengxiang {
    display: flex;
    flex-direction: column;

    .fx-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      margin-top: 10px;

      img {
        width: 36px;
        height: 31px;
      }

      .fx-item-text {
        display: flex;
        flex-direction: column;
        line-height: 16px;
        font-size: 14px;
      }
    }
  }
}

.fontGray {
  opacity: 0.5;
}
</style>
