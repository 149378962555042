<template>
  <!-- 新版总览页 -->
  <div class="page-home">
    <!-- 头部 -->
    <cephalosome @pup="pup" :returnData="returnData" @handleClose="handleClose"></cephalosome>
    <!-- 左边部分 -->
    <div class="columnLayout left">
      <div class="main-fun">
        <div class="item item1" @click="jump(1)">
          <div class="img"></div>
          <span>信息管理</span>
        </div>
        <div class="item item2" @click="jump(2)">
          <div class="img"></div>
          <span>飞行监控</span>
        </div>
        <div class="item item5" @click="jump(5)">
          <div class="img"></div>
          <span>侦测反制</span>
        </div>
        <div class="item item3" @click="jump(3)">
          <div class="img"></div>
          <span>场景应用</span>
        </div>
        <div class="item item4" @click="jump(4)">
          <div class="img"></div>
          <span>飞行培训</span>
        </div>
      </div>
      <!-- 安全管制 -->
      <ControlledAirspace class="controlled-air-space"></ControlledAirspace>
      <!-- 飞行报备 --- 安全预警 -->
      <duty class="duty" @pup="pup" :signType="signType" @changetype="changetype"></duty>
      <!-- 安全预警 -->
      <safety class="safety" @pup="pup"></safety>
    </div>
    <!-- 右边部分 -->
    <div class="columnLayout right columnLayout-right">
      <!--  气象信息  -->
      <weather class="qxxx" @pup="pup"></weather>
      <!--  飞行活动审批 -->
      <div class="examine">
        <examine ref="examine" @pup="pup" :gridsIndex="gridsIndex"></examine>
      </div>
      <!-- 呼叫应答   任务调度-->
      <div class="point">
        <!-- <Point></Point> -->
        <Point_uu v-if="pointFlag == 1"></Point_uu>
        <Point_whxf v-if="pointFlag == 2"></Point_whxf>
      </div>
    </div>
    <!-- 下边部分 -->
    <div class="rowLayout">
      <!-- 飞行统计 -->
      <fly></fly>
      <!-- 视频巡逻 -->
      <videoSurveillance v-if="false"></videoSurveillance>
    </div>
    <Popup ref="popup" v-if="isShow" @handleShow="handle_show"></Popup>
    <el-dialog :visible.sync="dialogVisible" :destroy-on-close="true">
      <div class="pup_style" v-if="dialogVisible">
        <feedback v-if="dialogId == 1" @handleClose="handleClose"></feedback>
        <meteorologicalCondition v-else-if="dialogId == 2" @handleClose="handleClose"></meteorologicalCondition>
        <approval v-else-if="dialogId == 3" @handleClose="handleClose" @pup="pup"></approval>
        <WorkLog v-else-if="dialogId == 4" @handleClose="handleClose" @pup="pup" @update="updateRoster" @beOnDuty="dialogId = 14"></WorkLog>
        <grade v-else-if="dialogId == 5" @handleClose="handleClose"></grade>
        <safetyFly v-else-if="dialogId == 6" @handleClose="handleClose"></safetyFly>
        <communication v-else-if="dialogId == 7" @handleClose="handleClose"></communication>
        <airpopup v-else-if="dialogId == 101" @handleClose="handleClose"></airpopup>
        <taskpopup v-else-if="dialogId == 102" @handleClose="handleClose"></taskpopup>
        <police v-else-if="dialogId == 103" @handleClose="handleClose"></police>
        <podetail v-else-if="dialogId == 104" @handleClose="handleClose"></podetail>
        <!-- 消息通知 -->
        <inform v-else-if="dialogId == 8" @handleClose="handleClose"></inform>
        <statistics v-else-if="dialogId == 9" @handleClose="handleClose"></statistics>
        <!-- 安全预警 更多 -->
        <earlyWarning v-else-if="dialogId == 10" @handleClose="handleClose"></earlyWarning>
        <report v-else-if="dialogId == 11" :saveData="saveData" @handleClose="handleClose"></report>
        <!-- 安全预警 点击操作按钮 -->
        <Warning v-else-if="dialogId == 12" @handleClose="handleClose" :saveData="saveData"></Warning>
        <addDutyinfo v-else-if="dialogId == 13" @handleClose="handleClose" @update="updateRoster"></addDutyinfo>
        <beOnDuty v-else-if="dialogId == 14" @handleClose="handleClose"></beOnDuty>
      </div>
    </el-dialog>
    <Update v-if="updateShow" :updateData="updateData" @close="updateShow = false" />
    <!-- 图例 -->
    <div class="legend" v-if="0">
      <div class="header" v-if="0">
        空域类型
      </div>
      <div class="body">
        <div v-for="(item, index) in legendList" class="legend-item">
          <div :style="{ width: '12px', height: '12px', backgroundColor: '' + item.color }"></div>
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
    <!-- 网格码提示 -->
    <div class="grids-tips" v-if="gridsFlag">
      网格码：
      <span v-show="gridsInfoShowFlag">{{ gridsInfo }}</span>
      <div v-show="!gridsInfoShowFlag" class="grids-custom-loader"></div>
    </div>
  </div>
</template>

<script>
import { base_layer } from '@/utils/global'
import { mapGetters } from 'vuex'
import Popup from '@/components/home/popup'
import methods from './methods'
import data from './data'
import API from '@/api'
import Update from './update'
import GeoJsonZHZ from '@/data/geo/geo_zhz.json'
import _ from 'lodash'
// 总览页头部
import cephalosome from './cephalosome/index.vue'
// 气象条件
import weather from './weather/index'
// 空域管理
import ControlledAirspace from './Controlled_Airspace/index.vue'
// 勤务报备
import duty from './duty/index.vue'
// 飞行统计
import fly from './fly/index.vue'
// 视频巡逻
import videoSurveillance from './video_surveillance/index.vue'
// 航线审批
import examine from './examine/index.vue'
// 安全预警
import safety from './safety/index.vue'
// 警情处置
// import alertDispose from "./alertDispose/index.vue";
// 实时点调
import Point from './Point/index.vue'
// UU跑腿实时点调
import Point_uu from './Point_uu/index.vue'
// 芜湖消防实时点调
import Point_whxf from './Point_whxf/index.vue'
// 问题反馈弹框
import feedback from './Confirmation_Dialogs/feedback/index.vue'
// 气象条件弹框
import meteorologicalCondition from './Confirmation_Dialogs/meteorological_condition/index.vue'
// 航线审批弹框
import approval from './Confirmation_Dialogs/approval/index.vue'
// 值班表
import WorkLog from './Confirmation_Dialogs/WorkLog/index.vue'
// 勤务等级弹框
import grade from './Confirmation_Dialogs/grade/index.vue'
// 安全飞行
import safetyFly from './Confirmation_Dialogs/safetyFly/index.vue'
// 通讯联络
import communication from './Confirmation_Dialogs/communication/index.vue'
//空域审批
import airpopup from './airpopup/index.vue'
// 任务审批
import taskpopup from './taskpopup/index.vue'
//警情派发
import police from './police/index.vue'
//警情详情
import podetail from './podetail/index.vue'
// 消息通知
import inform from './Confirmation_Dialogs/inform/index.vue'
// 报表统计
import statistics from './Confirmation_Dialogs/statistics/index.vue'
// 安全预警 更多弹窗
import earlyWarning from './Confirmation_Dialogs/early_warning/index.vue'
// 勤务报备
import report from './Confirmation_Dialogs/report/index.vue'
// 安全预警-立即通知 (预警通知)
import Warning from './Confirmation_Dialogs/Warning/index.vue'
// 值班信息录入
import addDutyinfo from '@/components/header-left/addDutyinfo'
// 民警飞手及驻场飞手一览表
import BeOnDuty from './Confirmation_Dialogs/beOnDuty/index.vue'
import cesiumSpaceAPI from '@/utils/cesiumSpace'
import * as turf from '@turf/turf'
import EventBus from "@/assets/ligature";

import wuhuGeoSot17 from '@/data/grid/wuhuGeoSot-20230821151223-17.json'
import { color } from 'highcharts';
import gridListData from '@/data/grid/airSpace_100_100_258.json';
import gridListDataWUHU_Origin from '@/data/grid/airSpace_wuhu_origin.json';
import gridListDataSHENZHEN_Origin from '@/data/grid/airSpace_shenzhen_origin.json';
import gridListDataDONGTAI_Origin from '@/data/grid/airSpace_dongtai_origin.json';


let threeDimensionalGridprimitivesList = [];
let gridBoxList = [];
let airLineEntityColloction = null;
let labelDivList = [];
let myheight = 5000;
let viewerExtent = [];
let currentGridsOriginData = gridListDataWUHU_Origin;
let gridsAirLineData = [];
// let gridsFlag = true;
export default {
  components: {
    Update,
    Popup,
    cephalosome,
    weather,
    ControlledAirspace,
    duty,
    fly,
    videoSurveillance,
    examine,
    safety,
    // alertDispose,
    Point,
    Point_uu,
    Point_whxf,
    feedback,
    meteorologicalCondition,
    approval,
    WorkLog,
    grade,
    safetyFly,
    communication,
    airpopup,
    taskpopup,
    police,
    podetail,
    inform,
    statistics,
    earlyWarning,
    report,
    Warning,
    addDutyinfo,
    BeOnDuty,
  },
  data() {
    return {
      ...data,
      isShow: false, //快捷栏
      dialogVisible: false, //弹框
      dialogId: null, //判断那个弹框
      saveData: null, //子组件传过来的值
      returnData: null, //弹框返回的数据
      pdata: null, //值班录入
      signType: 0, //常态、应急
      updateShow: false, //更新公告展示
      updateData: {}, //更新公告内容
      polylinePrimitive: null,
      polygonPrimitive: null,
      preHeight: 0,
      gridsIndex: 1,
      legendList: [
        { name: 'UOM适飞区', color: '#385623' },
        { name: '机场', color: '#89462C' },
        { name: '敏感区、高压线、高铁线', color: '#ED7D31' },
        { name: '企业申报空域', color: '#70AD47' },
      ],
      gridsFlag: false, //网格码开启与关闭
      gridsInfo: '',
      gridsInfoShowFlag: false,
      pointFlag: 1, // 0 默认，1 物流运输，2 应急消防
    }
  },
  async mounted() {
    this.getupdate()
    this.$store.commit('device/SET_WATERFlag', false)
    this.updatePliceWorkLevel();
    // 网格码开启与关闭
    EventBus.$on('gridsFlag', (v) => {
      this.gridsFlag = v;
      if (v) {
        console.log('开启网格码', v);
        if (!airLineEntityColloction) {
          airLineEntityColloction = new Cesium.CustomDataSource('airLineEntityColloction');
          window.viewer.dataSources.add(airLineEntityColloction);
        }

        let tileRender = window.viewer.scene.globe._surface._tilesToRender;
        let level = (tileRender && tileRender.length > 0) ? tileRender[0]._level : 9;
        let dis = this.getDisByLevel(level);
        this.initGridsByOriginDataAndDistance(dis, 6);
        this.gridsInfo = `${dis}*${dis}*${dis}`;
      } else {
        console.log('关闭网格码', v);
        this.clearGridsAirLineDiv();
      }
    });
    // 绘制网格码
    EventBus.$on('girds_ids', (item) => {
      if (item.name == '深圳') {
        window.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(114.355842, 21.287616, 120000),
          orientation: {
            heading: 0.006918732929549698,
            pitch: -0.6928665494487145,
            roll: 7.638334409421077e-14
          },
          duration: 3,
          complete: () => {
            // this.$message({
            //   type: 'warning',
            //   message: '暂无数据'
            // });
            this.clearGridsAirLineDiv();
            currentGridsOriginData = gridListDataSHENZHEN_Origin;
          }
        })
      } else if (item.name == '郑州') {
        window.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(113.646510, 34.031025, 80000),
          orientation: {
            heading: 0.006918732929549698,
            pitch: -0.6928665494487145,
            roll: 7.638334409421077e-14
          },
          duration: 3,
          complete: () => {
            this.$message({
              type: 'warning',
              message: '暂无数据'
            });
          }
        })
      } else if (item.name == '芜湖') {
        //芜湖
        window.viewer.camera.flyTo({
          //253
          // destination: Cesium.Cartesian3.fromDegrees(118.59375, 31.14856429463171, 7500),
          //258
          destination: Cesium.Cartesian3.fromDegrees(118.64120648259303, 31.184381561843814, 12000),
          orientation: {
            heading: Cesium.Math.toRadians(0.0),
            pitch: Cesium.Math.toRadians(-60.0),
            roll: 0.0
          },
          complete: () => {
            // this.$message({
            //   type: 'warning',
            //   message: '暂无数据'
            // });
            this.clearGridsAirLineDiv();
            currentGridsOriginData = gridListDataWUHU_Origin;
          }
        });
        // if (threeDimensionalGridprimitivesList.length == 0) {
        //   // this.addThreeDimensionalGridFun(gridListData);

        //   this.clearGridsAirLineDiv();
        //   currentGridsOriginData = gridListDataWUHU_Origin;

        // }
      } else if (item.name == '十堰') {
        let location = { "lon": 110.7862, "lat": 28.00, "height": 400000 };
        window.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(location.lon, location.lat, location.height),
          orientation: {
            heading: 0.006918732929549698,
            pitch: -0.6928665494487145,
            roll: 7.638334409421077e-14
          },
          duration: 3,
          complete: () => {
            EventBus.$emit("locationDone");
          }
        });
      } else if (item.name == '东台') {
        let location = { "lon": 120.60221873, "lat": 31.08398637, "height": 151812.321476599747 };
        window.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(location.lon, location.lat, location.height),
          orientation: {
            heading: 0.006918732929549698,
            pitch: -0.6928665494487145,
            roll: 7.638334409421077e-14
          },
          duration: 3,
          complete: () => {
            this.clearGridsAirLineDiv();
            currentGridsOriginData = gridListDataDONGTAI_Origin;
          }
        });
      }
      this.gridsIndex = item.index;
    });
    //绘制航线
    EventBus.$on('grid-airlines', (v) => {
      if (threeDimensionalGridprimitivesList.length == 0 && gridBoxList.length == 0) {
        this.$message({
          type: 'warning',
          message: '请先绘制网格'
        });
        return;
      }
      if (v.linesData.length == 0) {
        this.$message({
          type: 'warning',
          message: '请至少选择一条航线'
        });
        return;
      }
      console.log('v.linesData', v.linesData);

      gridsAirLineData = v.linesData;
      for (let i = 0; i < v.linesData.length; i++) {
        let lineData = v.linesData[i];
        // let crossGridColor = 'rgba(223, 70, 66, 0.5)'; // 航线经过网格码颜色
        let crossGridColor = lineData.crossGridColor; // 航线经过网格码颜色
        let airLineWidth = 2;
        let airLineColor = 'rgba(255, 255, 255, 1.0)';
        this.addAirLines(lineData, crossGridColor, airLineWidth, airLineColor);
      }
    });
    //清除网格，航线，弹出框
    EventBus.$on('grid-airlines-clearAll', () => {
      this.clearGridsAirLineDiv();
    });
    // 定位结束
    // EventBus.$on("locationDone", () => {
    //   console.log('定位芜湖首页3');
    // });
    this.initPointFlag();
  },
  methods: {
    ...methods,
    async getupdate() {
      let res = await API.HOME.getmessagecontent({
        isRead: 0,
        type: 7
      })
      // console.log(res,"update");
      if (res.length > 0) {
        console.log(res[0])
        this.updateData = res[0]
        this.updateShow = true
      }
    },
    //应急、常态
    changetype(i) {
      this.signType = i
    },
    // 快捷栏控制
    handle_show(item) {
      this.isShow = item
    },
    // 开局ceium定位
    async cesium_layer_change_mode(fn, modes) {
      // fn(modes.PLATE, "flyTo");
      // 添加禁飞区
      // return
      // console.log('添加禁飞区');
      await fn(modes.GLOBE)
      // return
      await this.addPointMap(window.viewer)

      airLineEntityColloction = new Cesium.CustomDataSource('airLineEntityColloction');
      window.viewer.dataSources.add(airLineEntityColloction);
      // 默认芜湖坐标
      let lon = 118.22073, lat = 29.50576, height = 151812.321476599747;
      // 读取配置文件里面的坐标
      let sceneConfigData = window.localStorage.getItem('sceneConfigData');
      sceneConfigData = JSON.parse(sceneConfigData);
      if (sceneConfigData && sceneConfigData['location'] && sceneConfigData['location'] != '') {
        let location = sceneConfigData['location'];
        lon = location.lon;
        lat = location.lat;
        height = location.height;
      }

      console.log('this.$store.state.user', this.$store.state.user);
      if (this.$store.state.user.user_info.username == "dongtai") {
        lon = 120.60221873;
        lat = 31.08398637;
        height = 151812.321476599747;
        console.log('定位到东台');
      }
      
      // 首页初始化定位芜湖市！！！
      window.viewer.camera.flyTo({
        // destination: Cesium.Cartesian3.fromDegrees(120.218241, 33.165767, 21812.321476599747),
        // 芜湖
        // destination: Cesium.Cartesian3.fromDegrees(118.22073, 29.50576, 151812.321476599747),
        // "lon": 113.18703, "lat": 22.84851, "height"
        destination: Cesium.Cartesian3.fromDegrees(lon, lat, height),
        orientation: {
          heading: 0.006918732929549698,
          pitch: -0.6928665494487145,
          roll: 7.638334409421077e-14
        },
        duration: 5,
        // complete: () => {
        // this.clearGridsAirLineDiv();
        // this.initGridsByOriginDataAndDistance(3000, 6);
        // this.gridsInfo = `${3000}*${3000}*${3000}`;
        // console.log('定位到芜湖市');
        // }
        complete: function () {
          // console.log('定位到芜湖市');
        }
      });

      // 开启websocket
      console.log('开启websocket')
      this.uav_init_ws()
      this.uav_init_ws_status()

      // 鼠标点击事件
      this.leftClickPop()

      // 绑定
      this.bindMapMove();
      // console.log('定位到芜湖市');


      // window.viewer.camera.changed.addEventListener((e) => {
      //   let a = window.viewer.camera.computeViewRectangle();
      //   console.log(a, 'aaaaaaaaaaaaaaaaaaaaa');
      // });

      // airLineEntityColloction = new Cesium.CustomDataSource('airLineEntityColloction');
      // console.log('window.viewer', window.viewer);
      // window.viewer.dataSources.add(airLineEntityColloction);
      // 添加网格码
      // this.getAirSpaceGeoSotGridsById();
      // for(let jj=0; jj<grids_list[0].ids.length; jj++){
      //   this.initGridsByIds(grids_list[0].ids[jj]);
      // }
      // this.initGridsByIds(grids_list[0].ids);


      //从服务器请求空域网格码（擦除了禁飞区）:这里的level后期需要调整
      // let grids = await API.AIRSPACE.getAirSpaceGeoSotGrid({ level: 16 })
      // // console.log("===================", grids)
      // if (grids.length > 0) {
      //   this.addGrids(grids);
      // } else {
      //   this.addGrids(wuhuGeoSot17);
      // }
    },
    //地图添加标注点 add 2023/5/23 31.1348650,118.5715
    async addPointMap(viewer) {
      let promise = Cesium.GeoJsonDataSource.load(GeoJsonZHZ)
      let dataSource = await promise
      let entities = dataSource.entities.values
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i]
        entity._name = 'home_point'
        entity.label = {
          text: name,
          show: true,
          showBackground: false,
          font: '16px monospace bolder',
          horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, 0),
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          //字体
          fillColor: Cesium.Color.fromCssColorString('#92D9FF') // 字体颜色
        }
        entity.billboard = {
          show: true,
          image: require('@/assets/zong_lan_ye_img/home_pt_location.png'),
          pixelOffset: new Cesium.Cartesian2(0, -30),
          width: 31,
          height: 40,
          scale: 0.7
        }
      }
      await viewer.dataSources.add(dataSource)
      dataSource.show = true
      window.home_point_source = dataSource
    },
    // 弹框控制
    // num:数字型,控制打开那个弹框
    pup(num, item) {
      this.dialogId = num
      this.saveData = item
      setTimeout(() => {
        this.dialogVisible = true
      }, 500)
    },
    close_pup() {
      this.dialogVisible = false
      this.dialogId = 0
    },
    // 弹框销毁
    async handleClose(item) {
      this.close_pup()
      // 接收的返回数据
      this.returnData = item
      if (item == 'app') {
        this.$refs.examine.get_arplist()
      } else if (item == 'police') {
        this.$refs.alertDispose.get_list()
      } else if (item == 'grade') {
        this.updatePliceWorkLevel()
      }
    },
    async updatePliceWorkLevel() {
      let res = await API.HOME.getPoliceWorkLevel()
      this.$store.commit('home/SET_START_GRADE', res.type)
    },
    //跳转
    jump(id, val) {
      // 信息管理
      if (id == 1) {
        let { href } = this.$router.resolve({ path: '/command?page=5' })
        localStorage.setItem('infoPage', 5)
        // console.log(href, 'href');
        // window.open(href, "_blank");
        window.open(href, '_self')
        this.$router.go(0)
        // window.open("/command?page=1","_blank")
      } else if (id == 2) {
        // 飞行监控
        let { href } = this.$router.resolve({ path: '/observe/home?page=1' })
        // window.open(url, "_blank");
        // 直接跳转到无人机应用
        // window.open("#/fckernel", "_blank");
        window.open('#/fckernel', '_self')
        this.$router.go(0)
        // window.open(href, "_blank");
      } else if (id == 3) {
        //场景应用
        let { href } = this.$router.resolve({ path: '/eagleStrike' })
        // window.open(href, "_blank");
        window.open(href, '_self')
        this.$router.go(0)
        // window.open("/eagleStrike","_blank")
      } else if (id == 4) {
        //飞行培训
        const FLYINGSESSIONID = this.$store.state.user.FLYINGSESSIONID
        const mmcIdentity = this.$store.state.user.IDENTITY
        let href = `${process.env.VUE_APP_LINK_URL}/hawk_train_new/#/home?FLYINGSESSIONID=${FLYINGSESSIONID}&mmcIdentity=${mmcIdentity}`
        // window.open(href, "_blank");
        window.open(href, '_self')
      } else if (id = 5) { // 侦测反制
        // window.open('http://60.205.91.11:9090/ltstation_V4.html', '_blank'); 
        // console.log('LHFJ_ZCFZ_TOKEN', LHFJ_ZCFZ_TOKEN);
        // let a = 'http://60.205.91.11:9090/ltstation_V4.html?token=U2FsdGVkX188CiUjIAjY+V7XLteHgpBoOOOLBP5YCqV+xv3pdcT96Y08Gg6I7hSL'
        // let b = encodeURIComponent(a)
        // console.log(b, 'b');
        // window.open(`${b}`, '_blank');
        window.open(`http://60.205.91.11:9090/ltstation_V4.html?token=${LHFJ_ZCFZ_TOKEN}`, '_blank');
      }
      // this.$router.go(0);
    },
    async get_duty() {
      let data = await API.USER.GetDuty()
      this.pdata = data
    },
    // 添加网格
    addGrids(gridsData) {
      // console.log(gridsData, 'gridsData')
      window.viewer.scene.postProcessStages.fxaa.enabled = true
      let polylineGeomInstanceArr = []
      let polygonGeomInstanceArr = []
      let len = gridsData.length

      for (let i = 0; i < len; i++) {
        gridsData[i].h = 0
        // 矩形
        let polygon = new Cesium.PolygonGeometry({
          // polygonHierarchy: new Cesium.PolygonHierarchy(
          //   Cesium.Cartesian3.fromDegreesArrayHeights([
          //     gridsData[i].l, gridsData[i].b, gridsData[i].h,
          //     gridsData[i].r, gridsData[i].b, gridsData[i].h,
          //     gridsData[i].r, gridsData[i].t, gridsData[i].h,
          //     gridsData[i].l, gridsData[i].t, gridsData[i].h,
          //   ])
          // )
          polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray([gridsData[i].l, gridsData[i].b, gridsData[i].r, gridsData[i].b, gridsData[i].r, gridsData[i].t, gridsData[i].l, gridsData[i].t])),
          height: 0 //设置默认为0，禁飞区为8 让禁飞区在网格码上面
        })
        let polygonGeometry = Cesium.PolygonGeometry.createGeometry(polygon)
        let polygonGeomInstance = new Cesium.GeometryInstance({
          geometry: polygonGeometry,
          attributes: {
            color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString('#9CF43F').withAlpha(0.4))
          }
        })
        // 多段线，边框

        let polylineGeometry = new Cesium.PolylineGeometry({
          // positions: Cesium.Cartesian3.fromDegreesArrayHeights([
          //     gridsData[i].l, gridsData[i].b, gridsData[i].h,
          //     gridsData[i].r, gridsData[i].b, gridsData[i].h,
          //     gridsData[i].r, gridsData[i].t, gridsData[i].h,
          //     gridsData[i].l, gridsData[i].t, gridsData[i].h,
          // ]),
          positions: Cesium.Cartesian3.fromDegreesArray([gridsData[i].l, gridsData[i].b, gridsData[i].r, gridsData[i].b, gridsData[i].r, gridsData[i].t, gridsData[i].l, gridsData[i].t, gridsData[i].l, gridsData[i].b]),
          width: 0.6
        })
        let polylineGeomInstance = new Cesium.GeometryInstance({
          geometry: polylineGeometry,
          attributes: {
            color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString('#89BE51').withAlpha(1.0))
          }
        })

        polylineGeomInstanceArr.push(polylineGeomInstance)
        polygonGeomInstanceArr.push(polygonGeomInstance)
      }

      if (this.polylinePrimitive) {
        window.viewer.scene.primitives.remove(this.polylinePrimitive)
      }
      if (this.polygonPrimitive) {
        window.viewer.scene.primitives.remove(this.polygonPrimitive)
      }

      this.polylinePrimitive = new Cesium.Primitive({
        geometryInstances: polylineGeomInstanceArr,
        vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT,
        appearance: new Cesium.PolylineColorAppearance({
          aboveGround: true,
          translucent: true
        })
      })
      this.polygonPrimitive = new Cesium.Primitive({
        geometryInstances: polygonGeomInstanceArr,
        vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT,
        appearance: new Cesium.PolylineColorAppearance({
          aboveGround: true,
          translucent: true
        })
      })

      window.viewer.scene.primitives.add(this.polylinePrimitive)
      window.viewer.scene.primitives.add(this.polygonPrimitive)
      // lowerToBottom
      window.viewer.scene.primitives.lowerToBottom(this.polylinePrimitive)
      window.viewer.scene.primitives.lowerToBottom(this.polygonPrimitive)

      // let pos = new Cesium.Cartesian3.fromDegrees(118.38560, 31.25481);
      // let en = new Cesium.Entity({
      //   position: pos,
      //   point: {
      //     pixelSize: 10,
      //     color: Cesium.Color.RED,
      //   },
      //   ellipse: {
      //     semiMajorAxis: 1000,
      //     semiMinorAxis: 1000,
      //     material: Cesium.Color.fromRandom().withAlpha(0.2),
      //     //拉伸
      //     extrudedHeight: 1,
      //     heightReference: Cesium.HeightReference.CLAMP_TO_EDGE,
      //     rotation: 0.9,
      //     shadows: Cesium.ShadowMode.ENABLED
      //   }
      // });
      // window.viewer.entities.add(en);
    },
    // primitive 和 entity
    te() {
      let entityPolygon = new Cesium.Entity({
        polygon: {
          hierarchy: {
            positions: Cesium.Cartesian3.fromDegreesArrayHeights([125.0, 47.0, 10000, 126.3, 47.3, 10000, 127.5, 43.6, 10000, 124.8, 40.3, 10000, 123.0, 45.0, 10000])
          },
          material: Cesium.Color.BLUE,
          height: 10000
        }
      })
      let polygon = new Cesium.PolygonGeometry({
        polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArrayHeights([125.0, 47.0, 50, 126.3, 47.3, 50, 127.5, 43.6, 50, 128.8, 40.3, 50, 129.0, 45.0, 50]))
      })
      let polygonGeometry = Cesium.PolygonGeometry.createGeometry(polygon)
      let polygonGeomInstance = new Cesium.GeometryInstance({
        geometry: polygonGeometry,
        attributes: {
          color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString('#ff0000').withAlpha(0.8))
        }
      })
      let a = new Cesium.Primitive({
        name: 'a',
        geometryInstances: polygonGeomInstance,
        vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT,
        appearance: new Cesium.PolylineColorAppearance({
          aboveGround: true,
          translucent: true,
          name: 'a'
        })
      })
      window.viewer.scene.primitives.add(a)
      let polygon1 = new Cesium.PolygonGeometry({
        polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArray([123.0, 47.0, 126.3, 47.3, 125.5, 45.6, 128.8, 46.3, 129.0, 47.0]))
      })
      let polygonGeometry1 = Cesium.PolygonGeometry.createGeometry(polygon1)
      let polygonGeomInstance1 = new Cesium.GeometryInstance({
        geometry: polygonGeometry1,
        attributes: {
          color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString('#ffff00').withAlpha(0.8))
        }
      })
      let a1 = new Cesium.Primitive({
        nam: 'a1',
        geometryInstances: polygonGeomInstance1,
        vertexFormat: Cesium.PolylineColorAppearance.VERTEX_FORMAT,
        appearance: new Cesium.PolylineColorAppearance({
          aboveGround: true,
          translucent: true,
          name: 'a1'
        })
      })
      window.viewer.scene.primitives.add(a1)
      window.viewer.scene.primitives.lowerToBottom(a1)
      window.viewer.scene.primitives.lowerToBottom(a)
      let polygonGeom = window.viewer.entities.add(entityPolygon)
      window.viewer.zoomTo(entityPolygon)
      let handler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas)
      handler.setInputAction((event) => {
        let pickedObjects = window.viewer.scene.drillPick(event.endPosition)
        if (Cesium.defined(pickedObjects)) {
          console.log(pickedObjects, 'pickedObjects')
        } else {
          console.log('pickedObjects', 'pickedObjects')
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE)
    },
    // 鼠标点击事件
    leftClickPop() {
      // home_uav_
      let handler = new Cesium.ScreenSpaceEventHandler(window.viewer.scene.canvas)
      handler.setInputAction(async (movement) => {
        let earthPosition = window.viewer.scene.camera.pickEllipsoid(movement.position, window.viewer.scene.globe.ellipsoid);
        console.log('movement.position', earthPosition);
        let ma = cesiumSpaceAPI.toDegrees(earthPosition);
        console.log('ma', ma);
        let pickedLabel = viewer.scene.pick(movement.position)
        let pick = new Cesium.Cartesian2(movement.position.x, movement.position.y)
        if (Cesium.defined(pickedLabel) && _.startsWith(pickedLabel.id.id, 'home_uav_')) {
          let modelEn = pickedLabel.id
          // 判断标牌是否已经创建
          if (modelEn.dom) {
            if (modelEn.dom.style.display == 'none') {
              modelEn.dom.style.display = 'block'
              modelEn.label.show = false
            } else {
              modelEn.dom.style.display = 'none'
              modelEn.label.show = true
            }
          } else {
            // 创建标牌
            let dom = document.createElement('div')
            dom.id = modelEn.id
            dom.className = 'uav-tips-class';
            dom.style.position = 'fixed'
            dom.style.left = parseFloat(movement.position.x) - 90 + 'px'
            dom.style.top = parseFloat(movement.position.y) - 200 + 'px'
            dom.style.zIndex = 99999999
            dom.style.fontSize = '13px'
            dom.style.borderRadius = '8px'
            dom.style.color = '#ffffff'
            document.body.appendChild(dom)
            modelEn.dom = dom
            let deviceData = modelEn._self
            let deviceName = deviceData.deviceName
            let height = deviceData.data.uavInfo.height + ' m'
            let longitude = deviceData.data.uavInfo.longitude
            let latitude = deviceData.data.uavInfo.latitude
            let taskId = deviceData.data.uavInfo.taskId
            let airSpeed = parseFloat(deviceData.data.uavInfo.airSpeed).toFixed(2) + ' m/s'
            let distanceToHome = parseFloat(deviceData.data.uavInfo.distanceToHome).toFixed(3) + ' m'
            if (String(deviceData.data.uavInfo.distanceToHome).indexOf('.') == -1) {
              distanceToHome = parseInt(deviceData.data.uavInfo.distanceToHome) + ' m';
            }
            let domTitle = document.createElement('div');
            domTitle.className = 'title';
            domTitle.innerHTML = `<span >无人机信息</span>`
            dom.appendChild(domTitle);
            let domContent = document.createElement('div');
            domContent.className = 'content';
            dom.appendChild(domContent);
            domContent.innerHTML = `
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>名称：</div>
                  <div style='text-align:left'>${deviceName}</div>
                </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px;; width:50px'>高度：</div>
                  <div style='text-align:left'>${height}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>经度：</div>
                  <div style='text-align:left'>${longitude}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>纬度：</div>
                  <div style='text-align:left'>${latitude}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>任务ID：</div>
                  <div style='text-align:left'>${taskId}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>速度：</div>
                  <div style='text-align:left'>${airSpeed}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>距离：</div>
                  <div style='text-align:left'>${distanceToHome}</div>
                  </div>`
            modelEn.label.show = false
          }
        }
        if (Cesium.defined(pickedLabel)) {
          let en = pickedLabel.id;
          console.log('en', en);
          if (en._name == 'airline-grid') {
            if (en?.myDom) {
              en.myDom.style.display = 'block';
              en.myDom.style.top = (movement.position.y - 150) + 'px';
              en.myDom.style.left = (movement.position.x - 104) + 'px';
              return;
            }
            // 添加标签div
            // let labelDiv = document.createElement('div');
            // labelDiv.classList.add('airline-label');
            // labelDiv.style.background = 'rgba(255, 255, 255, 0.5)';
            // labelDiv.style.position = 'absolute';
            // let headerDiv = document.createElement('div');
            // let bodyDiv = document.createElement('div');
            // headerDiv.classList.add('header');
            // bodyDiv.classList.add('body');
            // headerDiv.innerHTML = `<span>航线信息</span> <span class='close'>X</span>`;
            // bodyDiv.innerHTML =
            //    `<div><span class='label'>经纬度:</span> <span  class='v'>31.1875°N 118.5°E</span></div>
            //     <div><span class='label'>设备:</span> <span  class='v'>巡查2号</span></div>
            //     <div><span class='label'>高度:</span> <span  class='v'>800M</span></div>
            //     <div><span class='label'>时间:</span> <span  class='v vt'>2023.09.02 11:30-2023.09.02 13:00</span></div>`;
            // headerDiv.querySelectorAll('span')[1].addEventListener('click', function () {
            //   labelDiv.style.display = "none";
            // });
            // labelDiv.appendChild(headerDiv);
            // labelDiv.appendChild(bodyDiv);
            // labelDiv.style.top = (movement.position.y - 180) + 'px';
            // labelDiv.style.left = (movement.position.x - 134) + 'px';
            // document.body.appendChild(labelDiv);
            let labelDiv = this.createAirLineLabel(movement.position);
            en.myDom = labelDiv;
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      // 滚轮事件
      // handler.setInputAction(async (movement) => {
      //   console.log('wheel', movement);
      // }, Cesium.ScreenSpaceEventType.WHEEL);
      window.viewer.camera.moveEnd.addEventListener(() => {
        if (!this.gridsFlag) {
          return;
        }
        this.gridsInfoShowFlag = false;
        let tileRender = window.viewer.scene.globe._surface._tilesToRender;
        let level = (tileRender && tileRender.length > 0) ? tileRender[0]._level : 9;
        if (level <= 7) {
          if (myheight != 4000) {
            myheight = 4000;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(4000, 6);
          }
        } else if (level == 8) {
          if (myheight != 3500) {
            myheight = 3500;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(3500, 6);
          }
        } else if (level == 9) {
          if (myheight != 3000) {
            myheight = 3000;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(3000, 6);
          }
        } else if (level == 10) {
          if (myheight != 2000) {
            myheight = 2000;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(2000, 6);
          }
        } else if (level == 11) {
          if (myheight != 1500) {
            myheight = 1500;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(1500, 6);
          }
        } else if (level == 12) {
          if (myheight != 1000) {
            myheight = 1000;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(1000, 6);
          }
        } else if (level == 13) {
          if (myheight != 800) {
            myheight = 800;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(800, 5);
          }
        } else if (level == 14) {
          if (myheight != 600) {
            myheight = 600;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(600, 6);
          }
        } else if (level == 15) {
          if (myheight != 500) {
            myheight = 500;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(500, 6);
          }
        } else if (level == 16) {
          if (myheight != 450) {
            myheight = 450;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(450, 6);
          }
        } else if (level >= 17) {
          if (myheight != 300) {
            myheight = 300;
            this.gridsInfo = `${myheight}*${myheight}*${myheight}`;
            this.clearGridsAirLineDiv();
            this.initGridsByOriginDataAndDistance(300, 6);
          }
        }
        this.gridsInfoShowFlag = true;
      });
    },
    // 随地图移动
    bindMapMove() {
      window.viewer.scene.preRender.addEventListener((e) => {
        try {
          let a = window.viewer.dataSources._dataSources;
          // console.log('aaaaaaaa', a);
          let b = null;
          let d = null;
          for (let i = 0; i < a.length; i++) {
            if (a[i]._name == 'home_uav_custom_datasource') {
              b = a[i]._entityCollection._entities._array;
            }
            if (a[i]._name == 'airLineEntityColloction') {
              d = a[i]._entityCollection._entities._array;
            }
          }
          // console.log('bbbb', b);
          for (let j = 0; j < b.length; j++) {
            let c = b[j]._position._value
            // console.log('ccccccc', c);
            if (b[j].dom) {
              let position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(window.viewer.scene, c)
              b[j].dom.style.left = parseFloat(position.x) - 105 + 'px'
              b[j].dom.style.top = parseFloat(position.y) - 220 + 'px'
              let deviceData = b[j]._self
              let deviceName = deviceData.deviceName
              let height = deviceData.data.uavInfo.height + ' m'
              let longitude = deviceData.data.uavInfo.longitude
              let latitude = deviceData.data.uavInfo.latitude
              let taskId = deviceData.data.uavInfo.taskId
              let airSpeed = parseFloat(deviceData.data.uavInfo.airSpeed).toFixed(2) + ' m/s'
              let distanceToHome = parseFloat(deviceData.data.uavInfo.distanceToHome).toFixed(3) + ' m'
              if (String(deviceData.data.uavInfo.distanceToHome).indexOf('.') == -1) {
                distanceToHome = parseInt(deviceData.data.uavInfo.distanceToHome) + ' m';
              }
              let domContent = b[j].dom.querySelectorAll('.content');
              // console.log('domContent', domContent);
              domContent[0].innerHTML = `
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>名称：</div>
                  <div style='text-align:left'>${deviceName}</div>
                </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px;; width:50px'>高度：</div>
                  <div style='text-align:left'>${height}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>经度：</div>
                  <div style='text-align:left'>${longitude}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>纬度：</div>
                  <div style='text-align:left'>${latitude}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>任务ID：</div>
                  <div style='text-align:left'>${taskId}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>速度：</div>
                  <div style='text-align:left'>${airSpeed}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>距离：</div>
                  <div style='text-align:left'>${distanceToHome}</div>
                  </div>`
            }
          }
          for (let m = 0; m < d.length; m++) {
            if (d[m]._name && d[m]._name == 'airline-grid') {
              if (d[m]?.myDom) {
                let dom = d[m].myDom;
                let c = d[m]._position._value
                let position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(window.viewer.scene, c)
                dom.style.left = (parseFloat(position.x) - 134) + 'px'
                dom.style.top = (parseFloat(position.y) - 180) + 'px'
              }
            }
          }
        } catch (e) {
          console.log('bindMapMove...error', e);
        }
      });
    },
    /**@Description :  ******************************************* 航线生成网格码
     **@Date: 2024-04-07 10:13:10
     */
    async createRouteGrids() {
      let data = [
        { "x": 116.496255, "y": 39.914271, "z": 100, "speed": 10 },
        { "x": 116.279512, "y": 40.214271, "z": 100, "speed": 10 },
        { "x": 116.079512, "y": 39.214271, "z": 100, "speed": 10 },
        { "x": 116.679512, "y": 39.414271, "z": 100, "speed": 10 },
        { "x": 116.879512, "y": 40.514271, "z": 100, "speed": 10 },
      ];
      let res = await API.AIRWAY.createRouteGrids(13, data);
      // console.log('航线生成网格码', res);
    },

    /**@Description :  ******************************************* 生成并绘制网格码入口
     **@Date: 2024-04-10 10:19:45
     */
    async initGridsByIds(ids) {
      this.clearGridsAirLineDiv();
      let gridList = await this.getAirSpaceGeoSotGridsById(ids);
      return gridList;
    },
    /**@Description :  ******************************************* 根据已有的原始网格数据，生成新的网格并加载到地图上
     **@Date: 2024-04-15 15:32:31
     */
    async initGridsByOriginData(exHeight) {
      // return;
      let gridList = [];
      // gridListDataWUHU_Origin.length

      console.log('开始加载网格数据');
      let d1 = new Date();
      for (let i = 0; i < currentGridsOriginData.length; i++) {
        //判断是否在视野范围内
        if (viewerExtent.length != 0) {
          let maxLng = _.maxBy(currentGridsOriginData[i].data, 'r').r;
          let minLng = _.minBy(currentGridsOriginData[i].data, 'l').l;
          let maxLat = _.maxBy(currentGridsOriginData[i].data, 't').t;
          let minLat = _.minBy(currentGridsOriginData[i].data, 'b').b;
          if (viewerExtent[0] < minLng && viewerExtent[1] < minLat && viewerExtent[2] > maxLng && viewerExtent[3] > maxLat) {
            // 加载当前视野内网格
            let newGrids = this.reGenerateGridsByDistance(currentGridsOriginData[i].data, exHeight);
            let temp = {};
            temp.id = currentGridsOriginData[i].id;
            temp.data = newGrids.data;
            temp.bounds = newGrids.bounds;
            gridList.push(temp);
          }
        } else {
          // 加载所有网格
          let newGrids = this.reGenerateGridsByDistance(currentGridsOriginData[i].data, exHeight);
          let temp = {};
          temp.id = currentGridsOriginData[i].id;
          temp.data = newGrids.data;
          temp.bounds = newGrids.bounds;
          gridList.push(temp);
        }
      }
      let d2 = new Date();
      console.log('生成网格数据耗时: ', d2.getTime() - d1.getTime());
      this.addThreeDimensionalGridFun(gridList, exHeight);
      let d3 = new Date();
      console.log('绘制网格数据耗时: ', d3.getTime() - d2.getTime());
    },

    /**@Description :  ******************************************* 根据已有的原始网格数据和要生成的网格边长，生成新的网格码数据
     * 原始数据格式为数组
     * [
     *  {id:100, data:[{r:,l:,b:,t:,h},{r:,l:,b:,t:,h},...]},
     *  {id:100, data:[{r:,l:,b:,t:,h},{r:,l:,b:,t:,h},...]},
     *  ...
     * ]
     **@Date: 2024-04-17 11:16:57
     **@param {*} distance 立方体边长
     **@param {*} tier 网格拉伸层数
     */
    async initGridsByOriginDataAndDistance(distance, tier) {
      let gridList = [];
      // console.log('开始加载网格数据');
      let d1 = new Date();
      // 获取当前视图范围      
      let Rectangle = window.viewer.camera.computeViewRectangle();
      let viewerExtent = [Rectangle.west / Math.PI * 180, Rectangle.south / Math.PI * 180, Rectangle.east / Math.PI * 180, Rectangle.north / Math.PI * 180];
      // 当前视野面
      let viewerExtentPolyGon = turf.polygon([[
        [viewerExtent[0], viewerExtent[1]], // 注意：polygon首尾坐标要一致
        [viewerExtent[0], viewerExtent[3]],
        [viewerExtent[2], viewerExtent[3]],
        [viewerExtent[2], viewerExtent[1]],
        [viewerExtent[0], viewerExtent[1]]
      ]]);
      for (let i = 0; i < currentGridsOriginData.length; i++) {
        if (viewerExtent.length != 0) {
          let maxLon = _.maxBy(currentGridsOriginData[i].data, 'r').r;
          let minLon = _.minBy(currentGridsOriginData[i].data, 'l').l;
          let maxLat = _.maxBy(currentGridsOriginData[i].data, 't').t;
          let minLat = _.minBy(currentGridsOriginData[i].data, 'b').b;
          let originGridBounds = { maxLon, minLon, maxLat, minLat };
          //当前网格码面
          let maxGridPolygon = turf.polygon([[
            [minLon, minLat], // 注意：polygon首尾坐标要一致
            [minLon, maxLat],
            [maxLon, maxLat],
            [maxLon, minLat],
            [minLon, minLat]
          ]]);
          //判断是否有交集
          let intersection = turf.intersect(viewerExtentPolyGon, maxGridPolygon);
          if (intersection) {
            //将相交的原始网格码数据，重新生成等边网格码
            let newGrids = this.reGenerateGridsByDistance(currentGridsOriginData[i].data, distance, originGridBounds);
            let temp = {};
            temp.id = currentGridsOriginData[i].id;
            temp.data = newGrids.data;
            temp.bounds = newGrids.bounds;
            gridList.push(temp);
          }
        } else {
          let newGrids = this.reGenerateGridsByDistance(currentGridsOriginData[i].data, distance, originGridBounds);
          let temp = {};
          temp.id = currentGridsOriginData[i].id;
          temp.data = newGrids.data;
          temp.bounds = newGrids.bounds;
          gridList.push(temp);
        }
      }
      let d2 = new Date();
      console.log('生成网格数据耗时: ', d2.getTime() - d1.getTime());
      let d3 = new Date();
      // 根据重新生成的网格码数据，绘制网格
      this.addThreeDimensionalGridFun(gridList, distance, tier);
      console.log('绘制网格数据耗时: ', d3.getTime() - d2.getTime());
      setTimeout(() => {
        for (let i = 0; i < gridsAirLineData.length; i++) {
          let lineData = gridsAirLineData[i];
          // let crossGridColor = 'rgba(223, 70, 66, 0.5)'; // 航线经过网格码颜色
          let crossGridColor = lineData.crossGridColor; // 航线经过网格码颜色
          let airLineWidth = 2;
          let airLineColor = 'rgba(255, 255, 255, 1.0)';
          this.addAirLines(lineData, crossGridColor, airLineWidth, airLineColor);
        }
      }, 3000);
    },
    /**@Description :  ******************************************* 根据id获取网格码数据
     **@Date: 2024-04-08 14:41:54
     */
    async getAirSpaceGeoSotGridsById(ids) {
      // let ids = [259, 258, 257, 256, 255, 254, 253, 252, 251, 250];
      // ids = [258]
      let gridList = [];
      let newGrids = {};
      for (let i = 0; i < ids.length; i++) {
        // gridList = [];
        let res = await API.AIRWAY.getAirSpaceGeoSotGridsById(ids[i], 15);
        console.log('res-----', res);

        // return;
        // newGrids = this.reGenerateGrids(res.data);
        newGrids = this.reGenerateGridsByDistance(res.data, 200);
        console.log(`根据id获取网格码数据${ids[i]}`, res.data.length);
        console.log(`根据id获取网格码数据${ids[i]}`, res.data.length, newGrids.data.length);
        // console.log('newGrids', newGrids);
        let temp = {};
        temp.id = ids[i];
        temp.data = newGrids.data;
        temp.bounds = newGrids.bounds;
        gridList.push(temp);
      }
      return gridList;
    },
    /**@Description :  ******************************************* 根据新新生成的网格码数据，添加到地图上
     **@Date: 2024-04-12 09:31:05
     */
    addThreeDimensionalGridFun(gridList, exHeight, tier) {
      console.log('开始绘制网格数据');
      let heightLevel = tier; //拉伸几层
      // let exHeight = 5000; //每层拉伸高度
      let defaultGridColor = 'rgba(0,255,194,0.1)';//默认网格码颜色
      let gridOutLineColor = 'rgba(255, 255, 255, 0.2)';//网格码边框颜色
      this.addThreeDimensionalGrid(gridList, heightLevel, exHeight || 5000, defaultGridColor, gridOutLineColor);
    },
    /**@Description :  ******************************************* 根据接口获取的网格码重新生成新的正方形网格码
     * 此种方法生成的网格码按照原始网格码的间隔生成
     **@Date: 2024-04-09 17:42:31
     */
    reGenerateGrids(oriGrids) {
      let grids = [];
      let oriGrids0 = [
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.1875,
          "t": 31.203125,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.203125,
          "t": 31.21875,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.140625,
          "t": 31.15625,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.140625,
          "t": 31.15625,
          "h": 0
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.140625,
          "t": 31.15625,
          "h": 0
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.15625,
          "t": 31.171875,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.15625,
          "t": 31.171875,
          "h": 0
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.15625,
          "t": 31.171875,
          "h": 0
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.15625,
          "t": 31.171875,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.1875,
          "t": 31.203125,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.1875,
          "t": 31.203125,
          "h": 0
        }
      ];
      let oriGrids1 = [
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 30.9375,
          "t": 30.953125,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 30.953125,
          "t": 30.96875,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 30.953125,
          "t": 30.96875,
          "h": 0
        },
        {
          "r": 118.46875,
          "l": 118.453125,
          "b": 30.96875,
          "t": 30.984375,
          "h": 0
        },
        {
          "r": 118.46875,
          "l": 118.453125,
          "b": 30.984375,
          "t": 31,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 30.96875,
          "t": 30.984375,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 30.96875,
          "t": 30.984375,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 30.984375,
          "t": 31,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 30.984375,
          "t": 31,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 30.9375,
          "t": 30.953125,
          "h": 0
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 30.953125,
          "t": 30.96875,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 30.953125,
          "t": 30.96875,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 30.953125,
          "t": 30.96875,
          "h": 0
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 30.96875,
          "t": 30.984375,
          "h": -1
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 30.96875,
          "t": 30.984375,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 30.984375,
          "t": 31,
          "h": -1
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 30.984375,
          "t": 31,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 30.96875,
          "t": 30.984375,
          "h": 0
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 30.96875,
          "t": 30.984375,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 30.984375,
          "t": 31,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 30.984375,
          "t": 31,
          "h": -1
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 30.96875,
          "t": 30.984375,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 30.96875,
          "t": 30.984375,
          "h": 0
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 30.984375,
          "t": 31,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 30.984375,
          "t": 31,
          "h": 0
        },
        {
          "r": 118.46875,
          "l": 118.453125,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31,
          "t": 31.015625,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31,
          "t": 31.015625,
          "h": -1
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.046875,
          "t": 31.0625,
          "h": 0
        },
        {
          "r": 118.453125,
          "l": 118.4375,
          "b": 31.0625,
          "t": 31.078125,
          "h": 0
        },
        {
          "r": 118.46875,
          "l": 118.453125,
          "b": 31.0625,
          "t": 31.078125,
          "h": 0
        },
        {
          "r": 118.453125,
          "l": 118.4375,
          "b": 31.078125,
          "t": 31.09375,
          "h": 0
        },
        {
          "r": 118.46875,
          "l": 118.453125,
          "b": 31.078125,
          "t": 31.09375,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.0625,
          "t": 31.078125,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.0625,
          "t": 31.078125,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.078125,
          "t": 31.09375,
          "h": 0
        },
        {
          "r": 118.453125,
          "l": 118.4375,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.46875,
          "l": 118.453125,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31,
          "t": 31.015625,
          "h": -1
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31,
          "t": 31.015625,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.015625,
          "t": 31.03125,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31,
          "t": 31.015625,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31,
          "t": 31.015625,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.015625,
          "t": 31.03125,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.03125,
          "t": 31.046875,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.03125,
          "t": 31.046875,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.046875,
          "t": 31.0625,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.046875,
          "t": 31.0625,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.03125,
          "t": 31.046875,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.03125,
          "t": 31.046875,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.046875,
          "t": 31.0625,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.046875,
          "t": 31.0625,
          "h": -1
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31,
          "t": 31.015625,
          "h": 0
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.03125,
          "t": 31.046875,
          "h": -1
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.03125,
          "t": 31.046875,
          "h": 0
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.046875,
          "t": 31.0625,
          "h": -1
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.046875,
          "t": 31.0625,
          "h": 0
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.03125,
          "t": 31.046875,
          "h": 0
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.03125,
          "t": 31.046875,
          "h": -1
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.046875,
          "t": 31.0625,
          "h": 0
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.046875,
          "t": 31.0625,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.0625,
          "t": 31.078125,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.0625,
          "t": 31.078125,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.078125,
          "t": 31.09375,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.078125,
          "t": 31.09375,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.0625,
          "t": 31.078125,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.0625,
          "t": 31.078125,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.078125,
          "t": 31.09375,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.078125,
          "t": 31.09375,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.0625,
          "t": 31.078125,
          "h": -1
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.0625,
          "t": 31.078125,
          "h": -1
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.078125,
          "t": 31.09375,
          "h": -1
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.078125,
          "t": 31.09375,
          "h": -1
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.0625,
          "t": 31.078125,
          "h": -1
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.0625,
          "t": 31.078125,
          "h": -1
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.078125,
          "t": 31.09375,
          "h": -1
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.078125,
          "t": 31.09375,
          "h": -1
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.015625,
          "t": 31.03125,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.03125,
          "t": 31.046875,
          "h": 0
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.03125,
          "t": 31.046875,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.046875,
          "t": 31.0625,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.046875,
          "t": 31.0625,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.0625,
          "t": 31.078125,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.0625,
          "t": 31.078125,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.078125,
          "t": 31.09375,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.078125,
          "t": 31.09375,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.09375,
          "t": 31.109375,
          "h": 0
        }
      ];
      let oriGrids2 = [
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.203125,
          "t": 31.21875,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.21875,
          "t": 31.234375,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.234375,
          "t": 31.25,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.234375,
          "t": 31.25,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.25,
          "t": 31.265625,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.265625,
          "t": 31.28125,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.265625,
          "t": 31.28125,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.28125,
          "t": 31.296875,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.28125,
          "t": 31.296875,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.5,
          "l": 118.484375,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.484375,
          "l": 118.46875,
          "b": 31.3125,
          "t": 31.328125,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.140625,
          "t": 31.15625,
          "h": 0
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.140625,
          "t": 31.15625,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.15625,
          "t": 31.171875,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.15625,
          "t": 31.171875,
          "h": 0
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.15625,
          "t": 31.171875,
          "h": 0
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.171875,
          "t": 31.1875,
          "h": -1
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.171875,
          "t": 31.1875,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.203125,
          "t": 31.21875,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.203125,
          "t": 31.21875,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.203125,
          "t": 31.21875,
          "h": 0
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.203125,
          "t": 31.21875,
          "h": 0
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.234375,
          "t": 31.25,
          "h": -1
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.234375,
          "t": 31.25,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.234375,
          "t": 31.25,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.234375,
          "t": 31.25,
          "h": -1
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.1875,
          "t": 31.203125,
          "h": 0
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.203125,
          "t": 31.21875,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.203125,
          "t": 31.21875,
          "h": 0
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.1875,
          "t": 31.203125,
          "h": -1
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.1875,
          "t": 31.203125,
          "h": -1
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.203125,
          "t": 31.21875,
          "h": -1
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.203125,
          "t": 31.21875,
          "h": -1
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.234375,
          "t": 31.25,
          "h": -1
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.234375,
          "t": 31.25,
          "h": -1
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.234375,
          "t": 31.25,
          "h": -1
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.234375,
          "t": 31.25,
          "h": -1
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.15625,
          "t": 31.171875,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.671875,
          "l": 118.65625,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.6875,
          "l": 118.671875,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.703125,
          "l": 118.6875,
          "b": 31.171875,
          "t": 31.1875,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.1875,
          "t": 31.203125,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.1875,
          "t": 31.203125,
          "h": -1
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.203125,
          "t": 31.21875,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.203125,
          "t": 31.21875,
          "h": -1
        },
        {
          "r": 118.671875,
          "l": 118.65625,
          "b": 31.1875,
          "t": 31.203125,
          "h": -1
        },
        {
          "r": 118.6875,
          "l": 118.671875,
          "b": 31.1875,
          "t": 31.203125,
          "h": 0
        },
        {
          "r": 118.671875,
          "l": 118.65625,
          "b": 31.203125,
          "t": 31.21875,
          "h": -1
        },
        {
          "r": 118.6875,
          "l": 118.671875,
          "b": 31.203125,
          "t": 31.21875,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.21875,
          "t": 31.234375,
          "h": -1
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.234375,
          "t": 31.25,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.234375,
          "t": 31.25,
          "h": 0
        },
        {
          "r": 118.671875,
          "l": 118.65625,
          "b": 31.21875,
          "t": 31.234375,
          "h": 0
        },
        {
          "r": 118.6875,
          "l": 118.671875,
          "b": 31.21875,
          "t": 31.234375,
          "h": 0
        },
        {
          "r": 118.671875,
          "l": 118.65625,
          "b": 31.234375,
          "t": 31.25,
          "h": 0
        },
        {
          "r": 118.703125,
          "l": 118.6875,
          "b": 31.1875,
          "t": 31.203125,
          "h": 0
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.265625,
          "t": 31.28125,
          "h": -1
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.265625,
          "t": 31.28125,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.265625,
          "t": 31.28125,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.265625,
          "t": 31.28125,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.28125,
          "t": 31.296875,
          "h": -1
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.28125,
          "t": 31.296875,
          "h": -1
        },
        {
          "r": 118.515625,
          "l": 118.5,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.53125,
          "l": 118.515625,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.28125,
          "t": 31.296875,
          "h": -1
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.28125,
          "t": 31.296875,
          "h": 0
        },
        {
          "r": 118.546875,
          "l": 118.53125,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.5625,
          "l": 118.546875,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.265625,
          "t": 31.28125,
          "h": -1
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.265625,
          "t": 31.28125,
          "h": -1
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.265625,
          "t": 31.28125,
          "h": -1
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.265625,
          "t": 31.28125,
          "h": -1
        },
        {
          "r": 118.578125,
          "l": 118.5625,
          "b": 31.28125,
          "t": 31.296875,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.28125,
          "t": 31.296875,
          "h": 0
        },
        {
          "r": 118.59375,
          "l": 118.578125,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.28125,
          "t": 31.296875,
          "h": 0
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.28125,
          "t": 31.296875,
          "h": 0
        },
        {
          "r": 118.609375,
          "l": 118.59375,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.625,
          "l": 118.609375,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.25,
          "t": 31.265625,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.25,
          "t": 31.265625,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.265625,
          "t": 31.28125,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.265625,
          "t": 31.28125,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.28125,
          "t": 31.296875,
          "h": -1
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.28125,
          "t": 31.296875,
          "h": 0
        },
        {
          "r": 118.640625,
          "l": 118.625,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        },
        {
          "r": 118.65625,
          "l": 118.640625,
          "b": 31.296875,
          "t": 31.3125,
          "h": 0
        }
      ]
      let maxLon = _.maxBy(oriGrids, 'r');
      let minLon = _.minBy(oriGrids, 'l');
      let maxLat = _.maxBy(oriGrids, 't');
      let minLat = _.minBy(oriGrids, 'b');
      /**
       * 1 求出总四至矩形的长和宽，长和宽哪个大以哪个为主，形成一个正方形
       * 2 根据原始网格码间隔，求出一共有多少个间隔
       * 3 根据间隔求实际距离
       * 4 根据正方形四至距离，从左下角开始计算其他三个点的坐标
       * **/
      //左下角
      let szP1 = new Cesium.Cartesian3.fromDegrees(minLon.l, minLat.b);
      //左上角
      let szP2 = new Cesium.Cartesian3.fromDegrees(minLon.l, maxLat.t);
      //右上角
      let szP3 = new Cesium.Cartesian3.fromDegrees(maxLon.r, maxLat.t);
      //右下角
      let szP4 = new Cesium.Cartesian3.fromDegrees(maxLon.r, minLat.b);
      let szWidth = Cesium.Cartesian3.distance(szP2, szP3);
      let szHeight = Cesium.Cartesian3.distance(szP1, szP2);
      let squareSide = 0;
      let interval = 1;
      let c3 = new Cesium.Cartesian3(0, 0, 0);
      let c4 = new Cesium.Cartesian3(0, 0, 0);
      let szPoint1 = null;
      let szPoint2 = null;
      let szPoint3 = null;
      let szPoint4 = null;
      let lonInterval = 1;
      let latInterval = 1;
      if (szHeight > szWidth) {
        squareSide = szHeight;
        //纬度
        let oriInterval = oriGrids[0].t - oriGrids[0].b;
        let szInterval = maxLat.t - minLat.b;
        interval = Math.round(szInterval / oriInterval);
        let r = squareSide / szWidth;
        Cesium.Cartesian3.lerp(szP2, szP3, r, c3);
        Cesium.Cartesian3.lerp(szP1, szP4, r, c4);
        szPoint1 = cesiumSpaceAPI.toDegrees(szP1);
        szPoint2 = cesiumSpaceAPI.toDegrees(szP2);
        szPoint3 = cesiumSpaceAPI.toDegrees(c3);
        szPoint4 = cesiumSpaceAPI.toDegrees(c4);
      } else {
        //经度
        squareSide = szWidth;
        let oriInterval = oriGrids[0].r - oriGrids[0].l;
        let szInterval = maxLon.r - minLon.l;
        interval = Math.round(szInterval / oriInterval);
        let r = squareSide / szHeight;
        Cesium.Cartesian3.lerp(szP1, szP2, r, c3);
        Cesium.Cartesian3.lerp(szP4, szP3, r, c4);
        szPoint1 = cesiumSpaceAPI.toDegrees(szP1);
        szPoint2 = cesiumSpaceAPI.toDegrees(c3);
        szPoint3 = cesiumSpaceAPI.toDegrees(c4);
        szPoint4 = cesiumSpaceAPI.toDegrees(szP4);
      }
      console.log('正方形边长：', squareSide);
      lonInterval = (szPoint4.lng - szPoint1.lng) / interval;
      latInterval = (szPoint3.lat - szPoint4.lat) / interval;
      // interval = interval * 2;
      for (let i = 0; i < interval; i++) {
        for (let j = 0; j < interval; j++) {
          let lng = szPoint1.lng + i * lonInterval;
          let lat = szPoint1.lat + j * latInterval;
          let lng1 = szPoint1.lng + (i + 1) * lonInterval;
          let lat1 = szPoint1.lat + (j + 1) * latInterval;
          let p1 = new Cesium.Cartesian3.fromDegrees(lng, lat);
          let p2 = new Cesium.Cartesian3.fromDegrees(lng, lat1);
          let p3 = new Cesium.Cartesian3.fromDegrees(lng1, lat1);
          let p4 = new Cesium.Cartesian3.fromDegrees(lng1, lat);
          let intersectionFlag = false;
          let intersectionHeight = 0;
          let poly2 = turf.polygon([[
            [lng, lat], // 注意：polygon首尾坐标要一致
            [lng, lat1],
            [lng1, lat1],
            [lng1, lat],
            [lng, lat]
          ]]);
          for (let m = 0; m < oriGrids.length; m++) {
            let poly1 = turf.polygon([[
              [oriGrids[m].l, oriGrids[m].b], // 注意：polygon首尾坐标要一致
              [oriGrids[m].l, oriGrids[m].t],
              [oriGrids[m].r, oriGrids[m].t],
              [oriGrids[m].r, oriGrids[m].b],
              [oriGrids[m].l, oriGrids[m].b]
            ]]);
            let intersection = turf.intersect(poly1, poly2);
            if (intersection) {
              intersectionFlag = true;
              intersectionHeight = oriGrids[m].h;
            }
          }
          if (intersectionFlag) {
            // let en2 = new Cesium.Entity({
            //   polyline: {
            //     positions: [p1, p2, p3, p4, p1],
            //     width: 4,
            //     material: Cesium.Color.BLUE,
            //   }
            // });
            // window.viewer.entities.add(en2);
            let temp = {
              "r": lng1,
              "l": lng,
              "b": lat,
              "t": lat1,
              "h": intersectionHeight
            };
            grids.push(temp);
          }
        }
      }
      let sideLength = squareSide / interval;
      return {
        data: grids,
        height: sideLength,
        bounds: { west: minLon.l, south: minLat.b, east: maxLon.r, north: maxLat.t }
      };
    },
    /**@Description :  ******************************************* 根据接口获取的网格码重新生成新的正方形网格码
     * 此种方法生成的网格码按照指定的距离生成正方形网格码
     **@Date: 2024-04-11 15:55:52
     */
    reGenerateGridsByDistance(oriGrids, distance, originGridBounds) {
      let grids = [];
      let eveSideWidth = distance;
      let maxLon = originGridBounds.maxLon;
      let minLon = originGridBounds.minLon;
      let maxLat = originGridBounds.maxLat;
      let minLat = originGridBounds.minLat;
      // 获取原始网格边界 getOriGridsBounds
      let oriGridsRealBounds = this.getOriGridsBounds(oriGrids);
      let oriGridsBoundsPolygon = turf.polygon([oriGridsRealBounds]);
      /**
       * 1 求出总四至矩形的长和宽，长和宽哪个大以哪个为主，形成一个正方形
       * 2 根据原始网格码间隔，求出一共有多少个间隔
       * 3 根据间隔求实际距离
       * 4 根据正方形四至距离，从左下角开始计算其他三个点的坐标
       * **/
      // console.log('originGridBounds', originGridBounds);

      //左下角
      let szP1 = new Cesium.Cartesian3.fromDegrees(minLon, minLat);
      //左上角
      let szP2 = new Cesium.Cartesian3.fromDegrees(minLon, maxLat);
      //右上角
      let szP3 = new Cesium.Cartesian3.fromDegrees(maxLon, maxLat);
      //右下角
      let szP4 = new Cesium.Cartesian3.fromDegrees(maxLon, minLat);
      // console.log('szP1', szP1);
      // console.log('szP2', szP2);
      // console.log('szP3', szP3);
      // console.log('szP4', szP4);

      //最大范围的长宽
      let szWidth = Cesium.Cartesian3.distance(szP2, szP3);
      let szHeight = Cesium.Cartesian3.distance(szP1, szP2);
      let squareSide = 0;
      let interval = 0;
      let c3 = new Cesium.Cartesian3(0, 0, 0);
      let c4 = new Cesium.Cartesian3(0, 0, 0);
      //重新生成最大范围（正方形）的四至点
      let szPoint1 = null;
      let szPoint2 = null;
      let szPoint3 = null;
      let szPoint4 = null;
      if (szHeight > szWidth) {
        squareSide = szHeight;
        //纬度
        let oriInterval = oriGrids[0].t - oriGrids[0].b;
        let szInterval = maxLat - minLat;
        interval = Math.round(szInterval / oriInterval);
        let r = squareSide / szWidth;
        Cesium.Cartesian3.lerp(szP2, szP3, r, c3);
        Cesium.Cartesian3.lerp(szP1, szP4, r, c4);
        szPoint1 = cesiumSpaceAPI.toDegrees(szP1);
        szPoint2 = cesiumSpaceAPI.toDegrees(szP2);
        szPoint3 = cesiumSpaceAPI.toDegrees(c3);
        szPoint4 = cesiumSpaceAPI.toDegrees(c4);
      } else {
        //经度
        squareSide = szWidth;
        let oriInterval = oriGrids[0].r - oriGrids[0].l;
        let szInterval = maxLon - minLon;
        interval = Math.round(szInterval / oriInterval);
        let r = squareSide / szHeight;
        Cesium.Cartesian3.lerp(szP1, szP2, r, c3);
        Cesium.Cartesian3.lerp(szP4, szP3, r, c4);
        szPoint1 = cesiumSpaceAPI.toDegrees(szP1);
        szPoint2 = cesiumSpaceAPI.toDegrees(c3);
        szPoint3 = cesiumSpaceAPI.toDegrees(c4);
        szPoint4 = cesiumSpaceAPI.toDegrees(szP4);
      }
      // 根据每个网格的边长，计算出网格的间隔
      let girdInterval = Math.ceil(squareSide / eveSideWidth);
      let mk = szPoint1;
      // 每一个小网格的四至点
      let gp1 = null;
      let gp2 = null;
      let gp3 = null;
      let gp4 = null;
      let ePoint = turf.point([mk.lng, mk.lat]);
      let rSPoint = turf.point([mk.lng, mk.lat]);
      let gridIntervalLon = Math.ceil(szWidth / eveSideWidth);
      let gridIntervalLat = Math.ceil(szHeight / eveSideWidth);
      //总共的网格数 gridIntervalLon*gridIntervalLat
      for (let i = 0; i < gridIntervalLat; i++) {
        let dU = turf.destination(rSPoint, eveSideWidth * i, 0, { units: 'meters' });
        ePoint = turf.point([dU.geometry.coordinates[0], dU.geometry.coordinates[1]]);
        for (let j = 0; j < gridIntervalLon; j++) {
          let destination1 = turf.destination(ePoint, (eveSideWidth - 0.0) * (0 + 1), 90, { units: 'meters' });
          let destination2 = turf.destination(ePoint, eveSideWidth * (0 + 1), 0, { units: 'meters' });
          gp1 = new Cesium.Cartesian3.fromDegrees(ePoint.geometry.coordinates[0], ePoint.geometry.coordinates[1]);
          gp2 = new Cesium.Cartesian3.fromDegrees(destination2.geometry.coordinates[0], destination2.geometry.coordinates[1]);
          gp3 = new Cesium.Cartesian3.fromDegrees(destination1.geometry.coordinates[0], destination2.geometry.coordinates[1]);
          gp4 = new Cesium.Cartesian3.fromDegrees(destination1.geometry.coordinates[0], destination1.geometry.coordinates[1]);
          let d1 = Cesium.Cartesian3.distance(gp1, gp2);
          let d2 = Cesium.Cartesian3.distance(gp2, gp3);
          let intersectionHeight = 0;
          let lng = destination2.geometry.coordinates[0];
          let lat = destination1.geometry.coordinates[1];
          let lng1 = destination1.geometry.coordinates[0];
          let lat1 = destination2.geometry.coordinates[1];
          // 每一个小网格的四至点形成的面，正方形面
          let poly2 = turf.polygon([[[lng, lat], [lng, lat1], [lng1, lat1], [lng1, lat], [lng, lat]]]);
          // 判断是否与原始网格相交
          let cross = turf.intersect(poly2, oriGridsBoundsPolygon);
          if (cross) {
            let temp = { "r": lng1, "l": lng, "b": lat, "t": lat1, "h": intersectionHeight };
            grids.push(temp);
          }
          let nextPoint = turf.point([destination1.geometry.coordinates[0], destination1.geometry.coordinates[1]]);
          ePoint = nextPoint;
        }
      }
      return { data: grids, height: eveSideWidth, bounds: { west: minLon, south: minLat, east: maxLon, north: maxLat } };
    },
    reGenerateGridsByDistanceCopy1(oriGrids, distance, originGridBounds) {
      let grids = [];
      let eveSideWidth = distance;
      let maxLon = originGridBounds.maxLon;
      let minLon = originGridBounds.minLon;
      let maxLat = originGridBounds.maxLat;
      let minLat = originGridBounds.minLat;
      // 获取原始网格边界 getOriGridsBounds
      let oriGridsRealBounds = this.getOriGridsBounds(oriGrids);
      let oriGridsBoundsPolygon = turf.polygon([oriGridsRealBounds]);
      /**
       * 1 求出总四至矩形的长和宽，长和宽哪个大以哪个为主，形成一个正方形
       * 2 根据原始网格码间隔，求出一共有多少个间隔
       * 3 根据间隔求实际距离
       * 4 根据正方形四至距离，从左下角开始计算其他三个点的坐标
       * **/
      // console.log('originGridBounds', originGridBounds);

      //左下角
      let szP1 = new Cesium.Cartesian3.fromDegrees(minLon, minLat);
      //左上角
      let szP2 = new Cesium.Cartesian3.fromDegrees(minLon, maxLat);
      //右上角
      let szP3 = new Cesium.Cartesian3.fromDegrees(maxLon, maxLat);
      //右下角
      let szP4 = new Cesium.Cartesian3.fromDegrees(maxLon, minLat);
      // console.log('szP1', szP1);
      // console.log('szP2', szP2);
      // console.log('szP3', szP3);
      // console.log('szP4', szP4);

      //最大范围的长宽
      let szWidth = Cesium.Cartesian3.distance(szP2, szP3);
      let szHeight = Cesium.Cartesian3.distance(szP1, szP2);
      let squareSide = 0;
      let interval = 0;
      let c3 = new Cesium.Cartesian3(0, 0, 0);
      let c4 = new Cesium.Cartesian3(0, 0, 0);
      //重新生成最大范围（正方形）的四至点
      let szPoint1 = null;
      let szPoint2 = null;
      let szPoint3 = null;
      let szPoint4 = null;
      if (szHeight > szWidth) {
        squareSide = szHeight;
        //纬度
        let oriInterval = oriGrids[0].t - oriGrids[0].b;
        let szInterval = maxLat - minLat;
        interval = Math.round(szInterval / oriInterval);
        let r = squareSide / szWidth;
        Cesium.Cartesian3.lerp(szP2, szP3, r, c3);
        Cesium.Cartesian3.lerp(szP1, szP4, r, c4);
        szPoint1 = cesiumSpaceAPI.toDegrees(szP1);
        szPoint2 = cesiumSpaceAPI.toDegrees(szP2);
        szPoint3 = cesiumSpaceAPI.toDegrees(c3);
        szPoint4 = cesiumSpaceAPI.toDegrees(c4);
      } else {
        //经度
        squareSide = szWidth;
        let oriInterval = oriGrids[0].r - oriGrids[0].l;
        let szInterval = maxLon - minLon;
        interval = Math.round(szInterval / oriInterval);
        let r = squareSide / szHeight;
        Cesium.Cartesian3.lerp(szP1, szP2, r, c3);
        Cesium.Cartesian3.lerp(szP4, szP3, r, c4);
        szPoint1 = cesiumSpaceAPI.toDegrees(szP1);
        szPoint2 = cesiumSpaceAPI.toDegrees(c3);
        szPoint3 = cesiumSpaceAPI.toDegrees(c4);
        szPoint4 = cesiumSpaceAPI.toDegrees(szP4);
      }
      // console.log('squareSide', squareSide);
      // console.log('eveSideWidth', eveSideWidth);

      // 根据每个网格的边长，计算出网格的间隔
      let girdInterval = Math.ceil(squareSide / eveSideWidth);
      let mk = szPoint1;
      // 每一个小网格的四至点
      let gp1 = null;
      let gp2 = null;
      let gp3 = null;
      let gp4 = null;
      //总共的网格数 girdInterval*girdInterval
      // console.log('girdInterval', girdInterval);

      for (let i = 0; i < girdInterval; i++) {
        if (i >= 1) {
          mk = cesiumSpaceAPI.toDegrees(gp4);
          mk.lat = szPoint1.lat;
        }
        for (let j = 0; j < girdInterval; j++) {
          let point = turf.point([mk.lng, mk.lat]);
          // let destination1 = turf.destination(point, (eveSideWidth - 0.49) * (0 + 1), 90, { units: 'meters' });
          let destination1 = turf.destination(point, (eveSideWidth - 0.0) * (0 + 1), 90, { units: 'meters' });
          let destination2 = turf.destination(point, eveSideWidth * (0 + 1), 0, { units: 'meters' });
          gp1 = new Cesium.Cartesian3.fromDegrees(mk.lng, mk.lat);
          gp2 = new Cesium.Cartesian3.fromDegrees(destination2.geometry.coordinates[0], destination2.geometry.coordinates[1]);
          gp3 = new Cesium.Cartesian3.fromDegrees(destination1.geometry.coordinates[0], destination2.geometry.coordinates[1]);
          gp4 = new Cesium.Cartesian3.fromDegrees(destination1.geometry.coordinates[0], destination1.geometry.coordinates[1]);
          let d1 = Cesium.Cartesian3.distance(gp1, gp2);
          let d2 = Cesium.Cartesian3.distance(gp2, gp3);
          // console.log('diff', Math.abs(d1-d2));
          mk = cesiumSpaceAPI.toDegrees(gp2);
          let intersectionHeight = 0;
          let lng = destination2.geometry.coordinates[0];
          let lat = destination1.geometry.coordinates[1];
          let lng1 = destination1.geometry.coordinates[0];
          let lat1 = destination2.geometry.coordinates[1];
          // 每一个小网格的四至点形成的面，正方形面
          let poly2 = turf.polygon([[[lng, lat], [lng, lat1], [lng1, lat1], [lng1, lat], [lng, lat]]]);
          // 判断是否与原始网格相交
          let cross = turf.intersect(poly2, oriGridsBoundsPolygon);
          // console.log('crosscrosscross', cross);
          if (cross) {
            let temp = {
              "r": lng1,
              "l": lng,
              "b": lat,
              "t": lat1,
              "h": intersectionHeight
            };
            grids.push(temp);
          }
        }
      }
      return { data: grids, height: eveSideWidth, bounds: { west: minLon, south: minLat, east: maxLon, north: maxLat } };
    },
    reGenerateGridsByDistanceCopy(oriGrids, distance, a11) {
      let grids = [];
      let eveSideWidth = distance;
      let maxLon = _.maxBy(oriGrids, 'r');
      let minLon = _.minBy(oriGrids, 'l');
      let maxLat = _.maxBy(oriGrids, 't');
      let minLat = _.minBy(oriGrids, 'b');
      let c1 = new Cesium.Cartesian3.fromDegrees(minLon.l, minLat.b);
      // 获取原始网格边界 getOriGridsBounds
      let oriGridsBounds = this.getOriGridsBounds(oriGrids);
      let oriGridsBoundsPolygon = turf.polygon([oriGridsBounds]);
      /**
       * 1 求出总四至矩形的长和宽，长和宽哪个大以哪个为主，形成一个正方形
       * 2 根据原始网格码间隔，求出一共有多少个间隔
       * 3 根据间隔求实际距离
       * 4 根据正方形四至距离，从左下角开始计算其他三个点的坐标
       * **/
      //左下角
      let szP1 = new Cesium.Cartesian3.fromDegrees(minLon.l, minLat.b);
      //左上角
      let szP2 = new Cesium.Cartesian3.fromDegrees(minLon.l, maxLat.t);
      //右上角
      let szP3 = new Cesium.Cartesian3.fromDegrees(maxLon.r, maxLat.t);
      //右下角
      let szP4 = new Cesium.Cartesian3.fromDegrees(maxLon.r, minLat.b);
      let szWidth = Cesium.Cartesian3.distance(szP2, szP3);
      let szHeight = Cesium.Cartesian3.distance(szP1, szP2);
      let squareSide = 0;
      let interval = 0;
      let c3 = new Cesium.Cartesian3(0, 0, 0);
      let c4 = new Cesium.Cartesian3(0, 0, 0);
      let szPoint1 = null;
      let szPoint2 = null;
      let szPoint3 = null;
      let szPoint4 = null;
      if (szHeight > szWidth) {
        squareSide = szHeight;
        //纬度
        let oriInterval = oriGrids[0].t - oriGrids[0].b;
        let szInterval = maxLat.t - minLat.b;
        interval = Math.round(szInterval / oriInterval);
        let r = squareSide / szWidth;
        Cesium.Cartesian3.lerp(szP2, szP3, r, c3);
        Cesium.Cartesian3.lerp(szP1, szP4, r, c4);
        szPoint1 = cesiumSpaceAPI.toDegrees(szP1);
        szPoint2 = cesiumSpaceAPI.toDegrees(szP2);
        szPoint3 = cesiumSpaceAPI.toDegrees(c3);
        szPoint4 = cesiumSpaceAPI.toDegrees(c4);
      } else {
        //经度
        squareSide = szWidth;
        let oriInterval = oriGrids[0].r - oriGrids[0].l;
        let szInterval = maxLon.r - minLon.l;
        interval = Math.round(szInterval / oriInterval);
        let r = squareSide / szHeight;
        Cesium.Cartesian3.lerp(szP1, szP2, r, c3);
        Cesium.Cartesian3.lerp(szP4, szP3, r, c4);
        szPoint1 = cesiumSpaceAPI.toDegrees(szP1);
        szPoint2 = cesiumSpaceAPI.toDegrees(c3);
        szPoint3 = cesiumSpaceAPI.toDegrees(c4);
        szPoint4 = cesiumSpaceAPI.toDegrees(szP4);
      }
      // let c11 = cesiumSpaceAPI.toCartesian(szPoint1);
      // let c22 = cesiumSpaceAPI.toCartesian(szPoint2);
      // let c33 = cesiumSpaceAPI.toCartesian(szPoint3);
      // let c44 = cesiumSpaceAPI.toCartesian(szPoint4);
      // let enL = new Cesium.Entity({
      //   polyline: {
      //     positions: [c11, c22, c33, c44, c11],
      //     width: 2,
      //     material: Cesium.Color.PINK,
      //   }
      // });
      // viewer.value.entities.add(enL);
      let girdInterval = Math.ceil(squareSide / eveSideWidth);
      let mk = szPoint1;
      let gp1 = null;
      let gp2 = null;
      let gp3 = null;
      let gp4 = null;
      for (let i = 0; i < girdInterval; i++) {
        if (i >= 1) {
          mk = cesiumSpaceAPI.toDegrees(gp4);
          mk.lat = szPoint1.lat;
        }

        for (let j = 0; j < girdInterval; j++) {
          let point = turf.point([mk.lng, mk.lat]);
          let destination1 = turf.destination(point, (eveSideWidth - 0.49) * (0 + 1), 90, { units: 'meters' });
          let destination2 = turf.destination(point, eveSideWidth * (0 + 1), 0, { units: 'meters' });
          gp1 = new Cesium.Cartesian3.fromDegrees(mk.lng, mk.lat);
          gp2 = new Cesium.Cartesian3.fromDegrees(
            destination2.geometry.coordinates[0],
            destination2.geometry.coordinates[1]);
          gp3 = new Cesium.Cartesian3.fromDegrees(
            destination1.geometry.coordinates[0],
            destination2.geometry.coordinates[1]);
          gp4 = new Cesium.Cartesian3.fromDegrees(
            destination1.geometry.coordinates[0],
            destination1.geometry.coordinates[1]);
          mk = cesiumSpaceAPI.toDegrees(gp2);
          let intersectionFlag = true;
          let intersectionHeight = 0;
          let lng = destination2.geometry.coordinates[0];
          let lat = destination1.geometry.coordinates[1];
          let lng1 = destination1.geometry.coordinates[0];
          let lat1 = destination2.geometry.coordinates[1];
          let poly2 = turf.polygon([[
            [lng, lat], // 注意：polygon首尾坐标要一致
            [lng, lat1],
            [lng1, lat1],
            [lng1, lat],
            [lng, lat]
          ]]);
          // oriGrids.length
          let cross = turf.intersect(poly2, oriGridsBoundsPolygon);
          // console.log('cross', cross);


          // for (let m = 0; m < oriGrids.length; m++) {
          //   let poly1 = turf.polygon([[
          //     [oriGrids[m].l, oriGrids[m].b], // 注意：polygon首尾坐标要一致
          //     [oriGrids[m].l, oriGrids[m].t],
          //     [oriGrids[m].r, oriGrids[m].t],
          //     [oriGrids[m].r, oriGrids[m].b],
          //     [oriGrids[m].l, oriGrids[m].b]
          //   ]]);
          //   let intersection = turf.intersect(poly1, poly2);
          //   if (intersection) {
          //     intersectionFlag = true;
          //     intersectionHeight = oriGrids[m].h;
          //   }
          // }
          if (cross) {
            // let en2 = new Cesium.Entity({
            //   polyline: {
            //     positions: [gp1, gp2, gp3, gp4, gp1],
            //     width: 1,
            //     material: Cesium.Color.BLUE,
            //   }
            // });
            let result1 = new Cesium.Cartesian3(0, 0, 0);
            let result2 = new Cesium.Cartesian3(0, 0, 0);
            let result3 = new Cesium.Cartesian3(0, 0, 0);
            let result4 = new Cesium.Cartesian3(0, 0, 0);
            Cesium.Cartesian3.midpoint(gp1, gp2, result1);
            Cesium.Cartesian3.midpoint(gp2, gp3, result2);
            Cesium.Cartesian3.midpoint(gp3, gp4, result3);
            Cesium.Cartesian3.midpoint(gp4, gp1, result4);
            // viewer.value.entities.add(en2);
            let temp = {
              "r": lng1,
              "l": lng,
              "b": lat,
              "t": lat1,
              "h": intersectionHeight
            };
            grids.push(temp);
          }
        }
      }
      // return grids;
      return {
        data: grids,
        height: eveSideWidth,
        bounds: { west: minLon.l, south: minLat.b, east: maxLon.r, north: maxLat.t }
      };
    },
    /**@Description :  ******************************************* 获取裘总接口返回网格码边界（折点处斜线连接）
     **@Date: 2024-4-16 11:25:23
     */
    getOriGridsBounds(grids) {
      let lngMaxItem = _.maxBy(grids, 'r');
      let lngMinItem = _.minBy(grids, 'l');
      let latMaxItem = _.maxBy(grids, 't');
      let latMinItem = _.minBy(grids, 'b');
      let ycList = [];
      let zcList = [];
      let ubList = [];
      let dbList = [];
      let ycObjList = [];
      let zcObjList = [];
      let ubObjList = [];
      let dbObjList = [];
      for (let i = 0; i < grids.length; i++) {
        let pp1 = [grids[i].l, grids[i].b];
        let pp2 = [grids[i].l, grids[i].t];
        let pp3 = [grids[i].r, grids[i].t];
        let pp4 = [grids[i].r, grids[i].b];
        let fp = [pp1, pp2, pp3, pp4];
        for (let ii = 0; ii < 4; ii++) {
          let clng = fp[ii][0];
          let clat = fp[ii][1];
          //右侧线
          if (clng == lngMaxItem.r) {
            ycList.push([clng, clat]);
            ycObjList.push({ lng: clng, lat: clat });
          }
          //左侧线
          if (clng == lngMinItem.l) {
            zcList.push([clng, clat]);
            zcObjList.push({ lng: clng, lat: clat });
          }
          //顶上线
          if (clat == latMaxItem.t) {
            ubList.push([clng, clat]);
            ubObjList.push({ lng: clng, lat: clat });
          }
          //底下线
          if (clat == latMinItem.b) {
            dbList.push([clng, clat]);
            dbObjList.push({ lng: clng, lat: clat });
          }
        }
      }
      let a = _.orderBy(ycObjList, 'lat', 'asc');
      let b = _.orderBy(ubObjList, 'lng', 'desc');
      let c = _.orderBy(zcObjList, 'lat', 'desc');
      let d = _.orderBy(dbObjList, 'lng', 'asc');
      ycList = [];
      zcList = [];
      ubList = [];
      dbList = [];
      _(a).forEach((item) => {
        ycList.push([item.lng, item.lat]);
      });
      _(b).forEach((item) => {
        ubList.push([item.lng, item.lat]);
      });
      _(c).forEach((item) => {
        zcList.push([item.lng, item.lat]);
      });
      _(d).forEach((item) => {
        dbList.push([item.lng, item.lat]);
      });
      let aP = [...ycList, ...ubList, ...zcList, ...dbList];
      let line = turf.lineString(aP);
      let res = turf.cleanCoords(line).geometry.coordinates;
      res.push(res[0]);
      return res;
    },
    /**@Description :  ******************************************* 绘制立体网格码
     **@Date: 2024-04-08 14:46:34
     **@param {Array} grids 网格码数组
     **@param {Number} heightLevel 网格的拉伸层数
     **@param {Number} exHeight 网格的高度间隔，边长
     **@param {String} defaultGridColor 网格的默认颜色
     **@param {String} gridOutLineColor 网格的边框颜色
     */
    addThreeDimensionalGrid(gridList, heightLevel, exHeight, defaultGridColor, gridOutLineColor) {
      // console.log('gridList, heightLevel, exHeight, defaultGridColor, gridOutLineColor',
      // gridList, heightLevel, exHeight, defaultGridColor, gridOutLineColor);
      let threeDimensionalGridprimitives = null;
      let gridsBoxNameFaceList = [];
      threeDimensionalGridprimitivesList = []
      gridBoxList = [];
      let boundsList = [];
      for (let i = 0; i < gridList.length; i++) {
        threeDimensionalGridprimitives = new Cesium.PrimitiveCollection();
        gridsBoxNameFaceList = [];
        // 单一一个区域网格数据
        let grids = gridList[i];
        let id = grids.id;
        let polygonOutlineInstanceArray = [];
        let polygonInstanceArray = [];
        /**
         * 立体绘制，一共绘制 heightLevel 层
         * **/
        for (let m = 0; m < heightLevel; m++) {
          let h = m * exHeight;
          let eh = (m + 1) * exHeight;
          // 对每一个网格进行处理 grids.data.length
          for (let j = 0; j < grids.data.length; j++) {
            let item = grids.data[j];
            // 计算每个网格面
            let polygonPos = [item.r, item.b, h, item.r, item.t, h, item.l, item.t, h, item.l, item.b, h];
            // let dp1 = new Cesium.Cartesian3.fromDegrees(item.l, item.t);
            // let dp2 = new Cesium.Cartesian3.fromDegrees(item.r, item.t);
            // let dp3 = new Cesium.Cartesian3.fromDegrees(item.l, item.b);
            // let lonDist = Cesium.Cartesian3.distance(dp1, dp2);
            // let latDist = Cesium.Cartesian3.distance(dp1, dp3);
            // console.log('item.l, item.r, item.b, item.t', item.l, item.r, item.b, item.t);
            // console.log('dLon, dLat', dLon, dLat, lonDist, latDist);
            // console.log('lonDist, latDist', dLon, dLat, lonDist, latDist, Math.abs(lonDist - latDist));
            // console.log('立方体边长之差', Math.abs(lonDist - latDist));
            // 网格的前后上下左右6个面，保存起来，用来判断航线和立方体是否相交
            //底
            let face11 = [{ lon: item.r, lat: item.b, height: h },
            { lon: item.r, lat: item.t, height: h },
            { lon: item.l, lat: item.t, height: h },
            { lon: item.l, lat: item.b, height: h },
            ];
            //前
            let face12 = [{ lon: item.r, lat: item.b, height: h },
            { lon: item.r, lat: item.b, height: eh },
            { lon: item.l, lat: item.b, height: eh },
            { lon: item.l, lat: item.b, height: h },
            ];
            //顶
            let face13 = [{ lon: item.r, lat: item.b, height: eh },
            { lon: item.r, lat: item.t, height: eh },
            { lon: item.l, lat: item.t, height: eh },
            { lon: item.l, lat: item.b, height: eh },
            ];
            //后
            let face14 = [{ lon: item.r, lat: item.t, height: h },
            { lon: item.r, lat: item.t, height: eh },
            { lon: item.l, lat: item.t, height: eh },
            { lon: item.l, lat: item.t, height: h },
            ];
            //右
            let face15 = [{ lon: item.r, lat: item.b, height: h },
            { lon: item.r, lat: item.b, height: eh },
            { lon: item.r, lat: item.t, height: eh },
            { lon: item.r, lat: item.t, height: h },
            ];
            //左
            let face16 = [{ lon: item.l, lat: item.b, height: h },
            { lon: item.l, lat: item.b, height: eh },
            { lon: item.l, lat: item.t, height: eh },
            { lon: item.l, lat: item.t, height: h },
            ];
            let faceList = [face11, face12, face13, face14, face15, face16];
            let cesiumColor = Cesium.Color.fromCssColorString(defaultGridColor);
            // 开始绘制面(拉伸形成立方体)和边框的primitive
            let polygon = new Cesium.PolygonGeometry({
              polygonHierarchy: new Cesium.PolygonHierarchy(Cesium.Cartesian3.fromDegreesArrayHeights(polygonPos)),
              vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
              extrudedHeight: eh, //拉伸
              height: h //高度，必须设置，要不要然会从贴地拉伸
            });
            let polygonGeometry = Cesium.PolygonGeometry.createGeometry(polygon);
            // 给每一个立方体的id赋值，第1层第2个网格，和该网格所在区域id
            let polygonInstance = new Cesium.GeometryInstance({
              geometry: polygonGeometry,
              attributes: {
                color: Cesium.ColorGeometryInstanceAttribute.fromColor(cesiumColor)
              },
              id: m + '_' + j + '_' + 'grid_box_' + id,
            });
            // 将instance添加到数组中，最后统一添加到primitive中
            polygonInstanceArray.push(polygonInstance);
            // 外边框primitive
            let polygonOutlinePos = [
              item.r, item.b, eh,
              item.r, item.t, eh,
              item.l, item.t, eh,
              item.l, item.b, eh,
              item.r, item.b, eh,
              item.r, item.b, h,
              item.r, item.t, h,
              item.l, item.t, h,
              item.l, item.b, h,
              item.r, item.b, h,
              item.l, item.b, h,
              item.l, item.b, eh,
              item.l, item.t, eh,
              item.l, item.t, h,
              item.r, item.t, h,
              item.r, item.t, eh,
            ];
            let polygonOutlineInstance = new Cesium.GeometryInstance({
              geometry: Cesium.CoplanarPolygonOutlineGeometry.fromPositions({
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(polygonOutlinePos),
              }),
              attributes: {
                color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString(gridOutLineColor)),
              },
            });
            polygonOutlineInstanceArray.push(polygonOutlineInstance);
            // 保存面信息，用来判断航线和立方体是否相交
            let temp = {
              name: m + '_' + j + '_' + 'grid_box_' + id,
              face: faceList,
            }
            gridsBoxNameFaceList.push(temp);
          }
        }
        let primitivePolygonOutLine = new Cesium.Primitive({
          geometryInstances: polygonOutlineInstanceArray,
          appearance: new Cesium.PerInstanceColorAppearance({
            flat: true,
            renderState: {
              lineWidth: Math.min(1.0, window.viewer.scene.maximumAliasedLineWidth),
            },
          }),
        });
        let primitivePolygon = new Cesium.Primitive({
          geometryInstances: polygonInstanceArray,
          appearance: new Cesium.PerInstanceColorAppearance({
            closed: true,
          }),
          releaseGeometryInstances: false,
        });
        threeDimensionalGridprimitives.add(primitivePolygonOutLine);
        threeDimensionalGridprimitives.add(primitivePolygon);
        threeDimensionalGridprimitivesList.push({ 'primitives': threeDimensionalGridprimitives, 'id': id });
        gridBoxList.push({ 'item': gridsBoxNameFaceList, 'id': id });
        window.viewer.scene.primitives.add(threeDimensionalGridprimitives);
        boundsList.push(grids.bounds);
      }
    },
    addThreeDimensionalGridCopy(gridList, heightLevel, exHeight, defaultGridColor, gridOutLineColor) {
      let threeDimensionalGridprimitives = null;
      let gridsBoxNameFaceList = [];
      threeDimensionalGridprimitivesList = []
      gridBoxList = [];
      let boundsList = [];
      for (let i = 0; i < gridList.length; i++) {
        threeDimensionalGridprimitives = new Cesium.PrimitiveCollection();
        gridsBoxNameFaceList = [];
        let grids = gridList[i];
        let id = grids.id;
        let polygonOutlineInstanceArray = [];
        let polygonInstanceArray = [];
        //立体绘制，一共绘制 heightLevel 层
        for (let m = 0; m < heightLevel; m++) {
          let h = m * exHeight;
          let eh = (m + 1) * exHeight;
          // 对每一个网格进行处理 grids.data.length
          // let previewTop = -100;
          // console.log('grids.data.length', grids.data.length);
          for (let j = 0; j < grids.data.length; j++) {
            let item = grids.data[j];
            let polygonPos = [
              item.r, item.b, h,
              item.r, item.t, h,
              item.l, item.t, h,
              item.l, item.b, h
            ];
            let dLon = item.r - item.l;
            let dLat = item.t - item.b;
            let dp1 = new Cesium.Cartesian3.fromDegrees(item.l, item.t);
            let dp2 = new Cesium.Cartesian3.fromDegrees(item.r, item.t);
            let dp3 = new Cesium.Cartesian3.fromDegrees(item.l, item.b);
            let lonDist = Cesium.Cartesian3.distance(dp1, dp2);
            let latDist = Cesium.Cartesian3.distance(dp1, dp3);
            // console.log('item.l, item.r, item.b, item.t', item.l, item.r, item.b, item.t);
            // console.log('dLon, dLat', dLon, dLat, lonDist, latDist);
            // console.log('lonDist, latDist', dLon, dLat, lonDist, latDist, Math.abs(lonDist - latDist));
            // console.log('lonDist, latDist', Math.abs(lonDist - latDist));
            // 网格的前后上下左右6个面，保存起来
            //底
            let face11 = [
              { lon: item.r, lat: item.b, height: h },
              { lon: item.r, lat: item.t, height: h },
              { lon: item.l, lat: item.t, height: h },
              { lon: item.l, lat: item.b, height: h },
            ];
            //前
            let face12 = [
              { lon: item.r, lat: item.b, height: h },
              { lon: item.r, lat: item.b, height: eh },
              { lon: item.l, lat: item.b, height: eh },
              { lon: item.l, lat: item.b, height: h },
            ];
            //顶
            let face13 = [
              { lon: item.r, lat: item.b, height: eh },
              { lon: item.r, lat: item.t, height: eh },
              { lon: item.l, lat: item.t, height: eh },
              { lon: item.l, lat: item.b, height: eh },
            ];
            //后
            let face14 = [
              { lon: item.r, lat: item.t, height: h },
              { lon: item.r, lat: item.t, height: eh },
              { lon: item.l, lat: item.t, height: eh },
              { lon: item.l, lat: item.t, height: h },
            ];
            //右
            let face15 = [
              { lon: item.r, lat: item.b, height: h },
              { lon: item.r, lat: item.b, height: eh },
              { lon: item.r, lat: item.t, height: eh },
              { lon: item.r, lat: item.t, height: h },
            ];
            //左
            let face16 = [
              { lon: item.l, lat: item.b, height: h },
              { lon: item.l, lat: item.b, height: eh },
              { lon: item.l, lat: item.t, height: eh },
              { lon: item.l, lat: item.t, height: h },
            ];
            let faceList = [face11, face12, face13, face14, face15, face16];
            let cesiumColor = Cesium.Color.fromCssColorString(defaultGridColor);
            let polygon = new Cesium.PolygonGeometry({
              polygonHierarchy: new Cesium.PolygonHierarchy(
                Cesium.Cartesian3.fromDegreesArrayHeights(polygonPos)
              ),
              vertexFormat: Cesium.PerInstanceColorAppearance.VERTEX_FORMAT,
              extrudedHeight: eh, //拉伸
              height: h //高度，必须设置，要不要然会从贴地拉伸
            });
            let polygonGeometry = Cesium.PolygonGeometry.createGeometry(polygon);
            let polygonInstance = new Cesium.GeometryInstance({
              geometry: polygonGeometry,
              attributes: {
                color: Cesium.ColorGeometryInstanceAttribute.fromColor(cesiumColor)
              },
              id: m + '_' + j + '_' + 'grid_box_' + id,
            });
            polygonInstanceArray.push(polygonInstance);
            // 外边框primitive
            let polygonOutlinePos = [
              item.r, item.b, eh,
              item.r, item.t, eh,
              item.l, item.t, eh,
              item.l, item.b, eh,
              item.r, item.b, eh,
              item.r, item.b, h,
              item.r, item.t, h,
              item.l, item.t, h,
              item.l, item.b, h,
              item.r, item.b, h,
              item.l, item.b, h,
              item.l, item.b, eh,
              item.l, item.t, eh,
              item.l, item.t, h,
              item.r, item.t, h,
              item.r, item.t, eh,
            ];
            let polygonOutlineInstance = new Cesium.GeometryInstance({
              geometry: Cesium.CoplanarPolygonOutlineGeometry.fromPositions({
                positions: Cesium.Cartesian3.fromDegreesArrayHeights(polygonOutlinePos),
              }),
              attributes: {
                color: Cesium.ColorGeometryInstanceAttribute.fromColor(Cesium.Color.fromCssColorString(gridOutLineColor)),
              },
            });
            polygonOutlineInstanceArray.push(polygonOutlineInstance);
            // let primitivePolygon = new Cesium.Primitive({
            //   geometryInstances: [polygonInstance],
            //   appearance: new Cesium.PerInstanceColorAppearance({
            //     closed: true,
            //   }),
            //   releaseGeometryInstances: false,
            // });
            // primitivePolygon.name = m + '_' + j + '_' + 'grid_box_' + id;
            let temp = {
              // name: primitivePolygon.name,
              name: m + '_' + j + '_' + 'grid_box_' + id,
              face: faceList,
            }
            gridsBoxNameFaceList.push(temp);
            // 添加到集合
            // threeDimensionalGridprimitives.add(primitivePolygon);
          }
        }
        let primitivePolygonOutLine = new Cesium.Primitive({
          // geometryInstances: [polygonOutlineInstance],
          geometryInstances: polygonOutlineInstanceArray,
          appearance: new Cesium.PerInstanceColorAppearance({
            flat: true,
            renderState: {
              lineWidth: Math.min(1.0, window.viewer.scene.maximumAliasedLineWidth),
            },
          }),
        });
        let primitivePolygon = new Cesium.Primitive({
          geometryInstances: polygonInstanceArray,
          appearance: new Cesium.PerInstanceColorAppearance({
            closed: true,
          }),
          releaseGeometryInstances: false,
        });
        threeDimensionalGridprimitives.add(primitivePolygonOutLine);
        threeDimensionalGridprimitives.add(primitivePolygon);
        threeDimensionalGridprimitivesList.push({ 'primitives': threeDimensionalGridprimitives, 'id': id });
        gridBoxList.push({ 'item': gridsBoxNameFaceList, 'id': id });
        window.viewer.scene.primitives.add(threeDimensionalGridprimitives);
        boundsList.push(grids.bounds);
      }
      // console.log('boundsList', boundsList);
      let west = _.minBy(boundsList, 'west');
      let south = _.minBy(boundsList, 'south');
      let east = _.maxBy(boundsList, 'east');
      let north = _.maxBy(boundsList, 'north');
      // console.log('west, south, east, north', west, south, east, north);
      // console.log('heightLevel * exHeight * 8', heightLevel * exHeight * 8);
      // console.log('heightLevel * exHeight * 8', gridListData[0].data.length);
      // console.log('(west.west + east.east) / 2, (south.south + north.north) / 2.0025, heightLevel * exHeight * 15',
      // (west.west + east.east) / 1.9992, (south.south + north.north) / 2.0002, heightLevel * exHeight * 20);
      // window.viewer.camera.flyTo({
      //   destination: Cesium.Cartesian3.fromDegrees((west.west + east.east) / 1.9992, (south.south + north.north) / 2.0019, heightLevel * exHeight * 15),
      //   orientation: {
      //     heading: Cesium.Math.toRadians(0.0),
      //     pitch: Cesium.Math.toRadians(-60.0),
      //     roll: 0.0
      //   }
      // });
      // window.viewer.flyTo(threeDimensionalGridprimitives);
      // console.log('threeDimensionalGridprimitivesList', threeDimensionalGridprimitivesList);
      // console.log('gridBoxList', gridBoxList);

      // console.log('threeDimensionalGridprimitivesList', threeDimensionalGridprimitivesList);

      // let lineData = [
      //   { "x": 118.50, "y": 31.17, "z": 100, "speed": 10 },
      //   { "x": 118.51, "y": 31.18, "z": 500, "speed": 10 },
      //   { "x": 118.52, "y": 31.19, "z": 1000, "speed": 10 },
      //   { "x": 118.53, "y": 31.20, "z": 1200, "speed": 10 },
      //   { "x": 118.54, "y": 31.22, "z": 1500, "speed": 10 },
      // ];
      // let crossGridColor = 'rgba(223, 70, 66, 0.5)'; // 航线经过网格码颜色
      // let airLineWidth = 2;
      // let airLineColor = 'rgba(255, 255, 255, 1.0)';
      // this.addAirLines(lineData, crossGridColor, airLineWidth, airLineColor);
    },
    /**@Description :  ******************************************* 添加航线，判断是否穿过网格码
     **@Date: 2024-04-08 15:22:37
     */
    addAirLines(v, crossGridColor, airLineWidth, airLineColor) {
      let lineData = v.data;
      let linePos = [];
      for (let i = 0; i < lineData.length; i++) {
        let c3 = new Cesium.Cartesian3.fromDegrees(lineData[i].x, lineData[i].y, lineData[i].z);
        linePos.push(c3);
      }
      let lineSegment = [];
      let endBillBoardEntity = null;
      for (let i = 0; i < lineData.length - 1; i++) {
        let p1 = lineData[i];
        let p2 = lineData[i + 1];
        let temp1 = {}, temp2 = {};
        temp1 = { "lon": p1.x, "lat": p1.y, "height": p1.z };
        temp2 = { "lon": p2.x, "lat": p2.y, "height": p2.z };
        lineSegment.push([temp1, temp2]);
        let pos1 = new Cesium.Cartesian3.fromDegrees(p1.x, p1.y, p1.z);
        let pos2 = new Cesium.Cartesian3.fromDegrees(p2.x, p2.y, p2.z);
        let midPos = new Cesium.Cartesian3(0, 0, 0);
        Cesium.Cartesian3.midpoint(pos1, pos2, midPos);
        let angle = Cesium.Cartesian3.angleBetween(pos1, pos2);
        let northVector = new Cesium.Cartesian3(0.0, 0.0, 1.0);
        let toTarget = Cesium.Cartesian3.subtract(p2, p1, new Cesium.Cartesian3());
        let azimuth = Cesium.Math.zeroToTwoPi(Cesium.Math.PI_OVER_TWO - Cesium.Cartesian3.angleBetween(northVector, toTarget, new Cesium.Cartesian3()));
        azimuth -= Math.PI / 4;
        angle = Cesium.Math.toDegrees(azimuth);
        if (i == 0) {
          let en = new Cesium.Entity({
            position: pos1,
            billboard: {
              image: 'image/s.png',
              show: true,
              scale: 0.8,
              scaleByDistance: new Cesium.NearFarScalar(0.5e2, 1.0, 1.5e5, 0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
          });
          airLineEntityColloction.entities.add(en);
        }
        if (i == lineData.length - 2) {
          endBillBoardEntity = new Cesium.Entity({
            name: 'airline-grid',
            position: pos2,
            billboard: {
              image: 'image/e.png',
              show: true,
              scale: 0.8,
              rotation: azimuth,
              scaleByDistance: new Cesium.NearFarScalar(0.5e2, 1.0, 1.5e5, 0),
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
            },
          });
          airLineEntityColloction.entities.add(endBillBoardEntity);
          // window.viewer.flyTo(en);
        }
        let midEn = new Cesium.Entity({
          position: midPos,
          billboard: {
            image: 'image/l.png',
            show: true,
            scale: 0.8,
            rotation: azimuth,
            scaleByDistance: new Cesium.NearFarScalar(0.5e2, 1.0, 1.5e5, 0),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        });
        airLineEntityColloction.entities.add(midEn);
        let polylineEn = new Cesium.Entity({
          polyline: {
            positions: [pos1, pos2],
            width: airLineWidth,
            material: Cesium.Color.fromCssColorString(airLineColor),
          },
        });
        airLineEntityColloction.entities.add(polylineEn);
      }
      //创建航线标牌
      let last = lineData[lineData.length - 1];
      let cartesian3 = new Cesium.Cartesian3.fromDegrees(last.x, last.y, last.z);
      console.log('cartesian3', cartesian3);

      let c2 = Cesium.SceneTransforms.wgs84ToWindowCoordinates(window.viewer.scene, cartesian3);

      console.log('c2', c2);
      let labelDiv = this.createAirLineLabel(c2, v);
      endBillBoardEntity.myDom = labelDiv;
      //判断是否相交
      for (let i = 0; i < lineSegment.length; i++) {
        let myline = lineSegment[i];
        for (let ii = 0; ii < gridBoxList.length; ii++) {
          let listItem = gridBoxList[ii].item;
          for (let j = 0; j < listItem.length; j++) {
            let item = listItem[j];
            for (let k = 0; k < item.face.length; k++) {
              let face = item.face[k];
              let a = cesiumSpaceAPI.judgeSegmentIntersectPolyline(myline, face);
              if (a) {
                for (let t = 0; t < threeDimensionalGridprimitivesList.length; t++) {
                  for (let l = 0; l < threeDimensionalGridprimitivesList[t].primitives.length; l++) {
                    const p = threeDimensionalGridprimitivesList[t].primitives.get(l);
                    if (p.geometryInstances) {
                      let attr = p.getGeometryInstanceAttributes(item.name);
                      attr && (attr.color = Cesium.ColorGeometryInstanceAttribute.toValue(Cesium.Color.fromCssColorString(crossGridColor)));
                    }

                    // if (p.name == item.name) {
                    //   let attr = p.getGeometryInstanceAttributes(p.name);
                    //   attr && (attr.color = Cesium.ColorGeometryInstanceAttribute.toValue(Cesium.Color.fromCssColorString(crossGridColor)));
                    // }
                  }
                }
              }
            }
          }
        }
      }
    },
    /**@Description :  ******************************************* 生成航线弹出框
     **@Date: 2024-4-10 17:05:16
     */
    createAirLineLabel(c2, item) {
      let labelDiv = document.createElement('div');
      labelDiv.classList.add('airline-label');
      labelDiv.style.background = 'rgba(255, 255, 255, 0.5)';
      labelDiv.style.position = 'absolute';
      let headerDiv = document.createElement('div');
      let bodyDiv = document.createElement('div');
      headerDiv.classList.add('header');
      bodyDiv.classList.add('body');
      headerDiv.innerHTML = `<span>航线信息</span> <span class='close'>X</span>`;
      bodyDiv.innerHTML =
        `<div><span class='label'>名称:</span> <span  class='v'>${item.flightName}</span></div>
                <div><span class='label'>设备:</span> <span  class='v'>${item.deviceName}</span></div>
                <div><span class='label'>起始时间:</span> <span  class='v'>${item.startTime}</span></div>
                <div><span class='label'>终止时间:</span> <span  class='v'>${item.endTime}</span></div>`;
      headerDiv.querySelectorAll('span')[1].addEventListener('click', function () {
        labelDiv.style.display = "none";
      });
      labelDiv.appendChild(headerDiv);
      labelDiv.appendChild(bodyDiv);
      labelDiv.style.top = (c2.y - 180) + 'px';
      labelDiv.style.left = (c2.x - 134) + 'px';
      document.body.appendChild(labelDiv);
      labelDivList.push(labelDiv);
      return labelDiv;
    },
    /**@Description :  ******************************************* 清除网格，航线，弹出框
     **@Date: 2024-4-10 17:05:16
     */
    clearGridsAirLineDiv() {
      //清除航线
      airLineEntityColloction && airLineEntityColloction.entities.removeAll();
      //清除网格码，网格码一直保留，做隐藏
      for (let t = 0; t < threeDimensionalGridprimitivesList.length; t++) {
        threeDimensionalGridprimitivesList[t].primitives.removeAll();
        // let length = threeDimensionalGridprimitivesList[t].primitives.length;
        // for (let i = 0; i < length; ++i) {
        //   const p = threeDimensionalGridprimitivesList[t].primitives.get(i);
        //   p.show = false;
        // }
      }
      //销毁div
      for (let i = 0; i < labelDivList.length; i++) {
        labelDivList[i].remove();
      }
      threeDimensionalGridprimitivesList = [];
      gridBoxList = [];
      labelDivList = [];
    },
    /**@Description :  ******************************************* 根据地图层级获取网格距离
     **@Date: 2024-4-10 17:05:16
     */
    getDisByLevel(level) {
      if (level <= 7) {
        myheight = 4000;
        return myheight;
      } else if (level == 8) {
        myheight = 3500;
        return myheight;
      } else if (level == 9) {
        myheight = 3000;
        return myheight;
      } else if (level == 10) {
        myheight = 2000;
        return myheight;
      } else if (level == 11) {
        myheight = 1500;
        return myheight;
      } else if (level == 12) {
        myheight = 1000;
        return myheight;
      } else if (level == 13) {
        myheight = 800;
        return myheight;
      } else if (level == 14) {
        myheight = 600;
        return myheight;
      } else if (level == 15) {
        myheight = 500;
        return myheight;
      } else if (level == 16) {
        myheight = 450;
        return myheight;
      } else if (level >= 17) {
        myheight = 400;
        return myheight;
      }
    },
    /**@Description :  ******************************************* 创建无人机跟随标牌，该方法单独提取出来
     **@Date: 2024-4-10 17:05:16
     */
    createUavDivInfo(modelEn, position) {
      let a = window.viewer.dataSources._dataSources;





      // 创建标牌
      let dom = document.createElement('div')
      dom.id = modelEn.id
      dom.className = 'uav-tips-class';
      dom.style.position = 'fixed'
      dom.style.left = parseFloat(position.x) - 90 + 'px'
      dom.style.top = parseFloat(position.y) - 200 + 'px'
      dom.style.zIndex = 99999999
      dom.style.fontSize = '13px'
      dom.style.borderRadius = '8px'
      dom.style.color = '#ffffff'
      document.body.appendChild(dom)
      modelEn.dom = dom
      let deviceData = modelEn._self
      let deviceName = deviceData.deviceName
      let height = deviceData.data.uavInfo.height + ' m'
      let longitude = deviceData.data.uavInfo.longitude
      let latitude = deviceData.data.uavInfo.latitude
      let taskId = deviceData.data.uavInfo.taskId
      let airSpeed = parseFloat(deviceData.data.uavInfo.airSpeed).toFixed(2) + ' m/s'
      let distanceToHome = parseFloat(deviceData.data.uavInfo.distanceToHome).toFixed(3) + ' m'
      if (String(deviceData.data.uavInfo.distanceToHome).indexOf('.') == -1) {
        distanceToHome = parseInt(deviceData.data.uavInfo.distanceToHome) + ' m';
      }
      let domTitle = document.createElement('div');
      domTitle.className = 'title';
      domTitle.innerHTML = `<span >无人机信息</span>`
      dom.appendChild(domTitle);
      let domContent = document.createElement('div');
      domContent.className = 'content';
      dom.appendChild(domContent);
      domContent.innerHTML = `
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>名称：</div>
                  <div style='text-align:left'>${deviceName}</div>
                </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px;; width:50px'>高度：</div>
                  <div style='text-align:left'>${height}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>经度：</div>
                  <div style='text-align:left'>${longitude}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>纬度：</div>
                  <div style='text-align:left'>${latitude}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>任务ID：</div>
                  <div style='text-align:left'>${taskId}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>速度：</div>
                  <div style='text-align:left'>${airSpeed}</div>
                  </div>
                <div style='height: 14%; width: 100%; font-size: 12px; display: flex'>
                  <div style='color: rgba(24, 255, 251, 0.72); margin-right: 5px; width:50px'>距离：</div>
                  <div style='text-align:left'>${distanceToHome}</div>
                  </div>`
      modelEn.label.show = false;
    },
    // 根据不同场景设置不同跳转链接
    initPointFlag() {
      let sceneConfigData = window.localStorage.getItem('sceneConfigData');
      sceneConfigData = JSON.parse(sceneConfigData);
      if (sceneConfigData && sceneConfigData['flag']) {
        if (sceneConfigData['flag'] == '物流运输') {
          this.pointFlag = 1;
        }
        if (sceneConfigData['flag'] == '应急消防') {
          this.pointFlag = 2;
        }
      }

      // let pos = new Cesium.Cartesian3.fromDegrees(109.712168, 31.064604);
      // let en = new Cesium.Entity({
      //   position: pos,
      //   point: {
      //     pixelSize: 10,
      //     color: Cesium.Color.RED,
      //   },
      // });
      // window.viewer.entities.add(en);
      // window.viewer.flyTo(en);
      let point = turf.point([109.712168, 31.064604]);
      let distance1 = 2.5;
      let distance2 = 4;
      let bearing1 = 90;
      let bearing2 = -90;
      let bearing3 = 0;
      let bearing4 = 180;
      let options = { units: 'kilometers' };

      let destination1 = turf.destination(point, distance1, bearing1, options);
      let destination2 = turf.destination(point, distance1, bearing2, options);
      let destination3 = turf.destination(point, distance2, bearing3, options);
      let destination4 = turf.destination(point, distance2, bearing4, options);
      // console.log('destination1', destination1.geometry.coordinates);
      // console.log('destination2', destination2.geometry.coordinates);
      // console.log('destination3', destination3.geometry.coordinates);
      // console.log('destination4', destination4.geometry.coordinates);


      let a1 = 110.835335;
      let a2 = 32.531437;
      let a3 = 260.82;


      let pointEn = new Cesium.Entity({
        position: Cesium.Cartesian3.fromDegrees(a1, a2, a3),
        point: {
          pixelSize: 10,
          color: Cesium.Color.RED,
        },
      });
      window.viewer.entities.add(pointEn);
      window.viewer.flyTo(pointEn);
    },
    // 
  },
  computed: {
    ...mapGetters(['user_info']),
    home_popup() {
      return this.$refs.popup
    }
  },
  provide() {
    return {
      home_popup: () => this.home_popup,
      handle_check_popup_show: this.handle_check_popup_show
    }
  },
  beforeDestroy() {
    this.$store.commit('device/TOOLBAR_IS_SHOW', true)
  },
  inject: ['g_set_title']
}
</script>

<style lang="scss" scoped>
.ppp {
  position: fixed;
  top: 100px;
  left: 300px;
  z-index: 100;
  background-color: #00f5ff;
  cursor: pointer;
}

.page-home {
  // width: 100vw;
  height: 100%;
  background-color: #0c0e13;

  // position: relative;
  .menu {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 12px;

    .menu-item {
      width: calc(50% - 5px);
      height: 100px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: 12px;
      cursor: pointer;

      img {
        width: 100px;
        height: 92px;
      }

      span {
        font-size: 22px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 40px;
      }
    }

    .kong-color {
      background-color: #3b58f0;
    }

    .shi-color {
      background-color: #29afdf;
    }

    .ji-color {
      background-color: #1e93f8;
    }

    .xun-color {
      background-color: #1e82f8;
    }
  }

  .examine {
    width: 100%;
    height: 385px;
  }

  .point {
    width: 100%;
    flex: 1;
    height: 246px;
    margin-top: 12px;
  }

  .pup_style {
    position: absolute;
    left: 650px;
  }
}

.columnLayout {
  width: 21.8vw;
  //display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: 70px;
  z-index: 99;
  height: calc(100vh - 80px);
  //border: 1px solid yellow;
}

.rowLayout {
  display: flex;
  width: 100%;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  gap: 20px;
  bottom: 0px;
  left: 0;
  z-index: 98;
  box-sizing: border-box;
  padding-right: 60px;
}

// 左边
.left {
  left: 14px;
  padding: 0 10px;
  box-sizing: border-box;

  .main-fun {
    height: 12%;
    width: 100%;
    padding: 10px 0px;
    //border: 1px solid red;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    //justify-content: space-around;
    justify-content: space-between;

    .item {
      // width: 23%;
      // height: 98%;
      //border: 1px solid yellow;
      // background-image: url('../../assets/img/item.png');
      // background-size: 100% 100%;
      // background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      cursor: pointer;
      box-sizing: border-box;
      background: linear-gradient(180deg, #0efcf4 0%, #2b72ea 100%);
      // width: 94px;
      width: 18.5%;
      // height: 91px;
      height: 113%;
      border-radius: 12px;
      border: 1px solid #00f5ff;
      position: relative;

      .img {
        // width: 90%;
        // height: 72%;
        width: 37px;
        height: 41px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        margin-top: -10px;
        // border:1px solid #ff0000;
      }

      span {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        // margin-top: 12px;
        position: absolute;
        bottom: 2px;
        margin-bottom: 6px;
      }
    }

    .item1 {
      .img {
        background-image: url('~@/assets/img/i124.png');
      }
    }

    .item2 {
      .img {
        background-image: url('~@/assets/img/i125.png');
      }
    }

    .item3 {
      .img {
        background-image: url('../../assets/img/i126.png');
      }
    }

    .item4 {
      .img {
        background-image: url('../../assets/img/i127.png');
        // border:1px solid #ff0000;
      }
    }
    .item5 {
      .img {
        background-image: url('../../assets/img/fz.png');
        // border:1px solid #ff0000;
        background-size: 100% 100%;
        // scale: 1.7;
        transform: scale(1.08, 1.0);
        // margin-top: -10px;
      }
    }
  }

  .controlled-air-space {
    height: 24%;
    width: 100%;
    margin-top: 10px;
    //padding: 10px 20px;
  }

  .duty {
    height: 30%;
    width: 100%;
    margin-top: 15px;
  }

  .safety {
    height: 27%;
    width: 100%;
    margin-top: 15px;
  }
}

.right {
  right: 14px;

  .qxxx {
    width: 100%;
    height: 18%;
  }

  .examine {
    height: 45%;
    //border: 1px solid blue;
  }

  .point {
    height: 33%;
    //border: 1px solid blue;
  }
}

.xia {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  height: calc(100vh - 79px);
  position: absolute;
  top: 79px;
  left: 0px;
  width: 100vw;
  padding-left: 14px;
  padding-right: 14px;
}

/deep/ .cesium-viewer-toolbar {
  // position: relative;
  top: 200px !important;
  right: 850px !important;
}

// 弹框样式
/deep/ .el-dialog {
  background-image: none;
  background: transparent;
  box-shadow: none;

  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    padding: 0;
    width: auto;
    display: flex;
    justify-content: center;
    height: auto !important;
    background: none !important;
    border: 0 !important;
    backdrop-filter: none !important;
  }
}

.switch-version {
  position: fixed;
  left: 480px;
  top: 120px;
  cursor: pointer;
}

.legend {
  z-index: 1000;
  position: fixed;
  right: 22.8vw;
  bottom: 2.8vh;
  // width: 100px;
  width: auto;
  height: 130px;
  background-color: #00f5ff;
  // background: linear-gradient( 1, rgba(24,95,146,0.71) 38%, rgba(0,59,83,0.78) 100%);
  // border-radius: 0px 0px 0px 0px;
  // border: 0 solid;
  // border-image: linear-gradient(180deg, rgba(71, 160, 255, 0.06), rgba(123, 187, 255, 1), rgba(71, 167, 255, 0.2)) 1 1;
  // background-image: linear-gradient(179deg,#185F92 1%,#003B53 100%);
  background: rgba(22, 39, 66, 0.56);
  border-radius: 0px 0px 0px 0px;
  border: 1px solid rgba(0, 245, 255, 0.12);

  .header {
    width: 100%;
    height: 20%;
    // background-color: #1e82f8;
    box-sizing: border-box;
    padding: 0 10px;
    // width: 56px;
    // height: 19px;
    font-family: Alibaba PuHuiTi, Alibaba PuHuiTi;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
    background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 35%, #56CCFF 96%);
    // color: linear-gradient(to right, red, yellow) !important;
    // background: linear-gradient(to right, #ff0000, #ffff00); /*设置渐变的方向从左到右 颜色从ff0000到ffff00*/
    -webkit-background-clip: text;
    /*将设置的背景颜色限制在文字中*/
    -webkit-text-fill-color: transparent;
    /*给文字设置成透明*/
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 10px 0;

    .legend-item {
      width: 100%;
      height: 25%;
      // background-color: #00f5ff;
      box-sizing: border-box;
      padding: 10px 14px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      span {
        // width: 52px;
        // background-color: #00f5ff;
        height: 17px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 500;
        font-size: 12px;
        color: #C4EAFF;
        line-height: 14px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        letter-spacing: 1.0px;
        margin-left: 8px;
      }
    }
  }
}

.grids-tips {
  position: fixed;
  right: 22.8vw;
  bottom: .1vh;
  width: auto;
  height: 20px;
  background-color: rgba(20, 33, 63, 0.6);
  color: #FFFFFF;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 12px 10px;
  border-radius: 4px;
}

.grids-custom-loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 3px solid;
  border-color: #E4E4ED;
  border-right-color: #1F516C;
  animation: s2 1s infinite linear;
}

@keyframes s2 {
  to {
    transform: rotate(1turn)
  }
}
</style>
<style lang="scss">
.airline-label {
  height: 155px !important;
  width: 268px !important;
  background-image: url('~@/assets/images/b.png') !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;

  .header {
    height: 32px !important;
    width: 268px !important;
    background-image: url('~@/assets/images/h.png') !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: #ffffff !important;
      font-size: 18px !important;
      font-weight: bold;
      margin-left: 20px;
      letter-spacing: 2px;
      font-style: italic;
    }

    .close {
      cursor: pointer;
      font-weight: normal;
      margin-right: 10px;
      font-style: normal;
    }
  }

  .body {
    height: 123px !important;
    width: 268px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    div {
      width: 100%;
      height: 20%;
      box-sizing: border-box;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .label {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      text-shadow: 0px 4px 6px rgba(24, 255, 251, 0.32), inset 0px 1px 4px rgba(255, 255, 255, 0.5);
      text-align: left;
      font-style: normal;
      text-transform: none;
      color: #73CFD4;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .v {
      font-family: PingFang SC, PingFang SC;
      font-weight: 500;
      font-size: 13px;
      // line-height: 14px;
      // text-align: center;
      font-style: normal;
      text-transform: none;
      color: #ffffff;
      margin-left: 10px;
      width: 70%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .vt {
      font-size: 12px;
      align-items: left;
    }
  }
}

.uav-tips-class {
  background-image: url("~@/assets/img/uav_tips.png") !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  // border: 10px solid #ff0000;
  height: 200px;
  width: 215px;

  .title {
    width: 211px;
    height: 28px;
    background: linear-gradient(90deg, rgba(154, 255, 249, 0) 0%, rgba(97, 246, 255, 0.75) 46%, rgba(154, 255, 249, 0) 100%);
    border-radius: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("~@/assets/img/title-1.png") !important;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    // font-weight: 600;
    // width: 74px;
    // height: 0px;
    font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
    font-weight: 400;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.73);
    line-height: 0px;
    text-shadow: 0px 0px 14px #6FB1FF, 0px 0px 4px rgba(0, 6, 10, 0.8);
    text-align: left;
    font-style: normal;
    text-transform: none;
    background: linear-gradient(89.99999999186055deg, #FFFFFF 0%, #5ABAFF 100%);


  }

  .content {
    width: 211px;
    height: 162px;
    // border:1px solid #ff0000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-sizing: border-box;
    padding: 10px;
  }
}
</style>
